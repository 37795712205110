import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ButtonComponent, Popup, TextField } from '../../atoms';
import { Table } from '../../molecules';
import { RemarkModal } from '../RemarkModal';
import {
	Container,
	InputContainer,
	Label,
	Label2,
	TableContainer,
} from './styles';
import { useMutation } from 'react-query';
import { getAllOrders } from '../../../services/orders';
import { Tooltip } from 'antd';
import { theme } from '../../../theme';
import { SelectComponent } from '../../atoms/Select';
import { ORDERS_STATUS_OPTIONS } from '../../../utils/orders';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { getTimestampInMillisecond, useAlgoliaSearch } from '../../../utils';

export const OrderListTable = () => {
	const [showModal, setShowModal] = useState(false);
	const [loadingAlgoliaData, setLoadingAlgoliaData] = useState(false);
	const [tableData, setTableData] = useState({ data: [], count: 0 });
	const [remarksDetail, setRemarksDetail] = useState({
		id: '',
		remarks: '',
	});
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const page = searchParams.get('page');

	const formik = useFormik({
		initialValues: {
			full_name: '',
			corporate_name: '',
			email: '',
			status: '',
		},
		enableReinitialize: true,
		onSubmit: async (values: any) => {
			if (Object.values(values).filter((item) => !!item)?.length) {
				localStorage.setItem(
					'order-formik-values',
					JSON.stringify(formik.values)
				);
				setLoadingAlgoliaData(true);
				const searchedData: any = await useAlgoliaSearch(values, page);
				setLoadingAlgoliaData(false);
				if (searchedData?.count <= 10 && Number(page) !== 1) {
					navigate('/orders?page=1');
				}
				setTableData(searchedData);
			} else {
				mutate('1');
				localStorage.removeItem('order-formik-values');
			}
		},
	});

	useEffect(() => {
		(async () => {
			const formikValuesFromLocalStorage = localStorage.getItem(
				'order-formik-values'
			);
			const parsedValue =
				formikValuesFromLocalStorage &&
				JSON.parse(formikValuesFromLocalStorage);

			if (parsedValue) {
				formik.setValues(JSON.parse(formikValuesFromLocalStorage));
				setLoadingAlgoliaData(true);
				const searchedData: any = await useAlgoliaSearch(parsedValue, page);
				setTableData(searchedData);
				setLoadingAlgoliaData(false);
			} else {
				mutate(page);
			}
		})();
	}, [page]);

	const { mutate, isLoading } = useMutation(['getOrders'], getAllOrders, {
		onSuccess: ({ data, count }) => {
			const dataWithKey = {
				data: data?.map((item: any, index: any) => ({ ...item, key: index })),
				count: count,
			};
			setTableData(dataWithKey);
		},
	});

	const columns = [
		{
			title: t('Order No.'),
			dataIndex: 'order_id',
			width: 100,
			render: (order_id: number) => {
				return <span>{`${order_id}`?.padStart(3, '0')}</span>;
			},
		},
		{
			title: t('Corporate name'),
			dataIndex: 'corporate_name',
			width: 180,
		},
		{
			title: t('Name'),
			dataIndex: 'full_name',
			width: 180,
		},
		{
			title: t('Invoice name'),
			dataIndex: 'invoice_name',
			width: 180,
		},
		{
			title: t('Status'),
			dataIndex: 'status',
			width: 180,
			render: (data: string) => {
				return <span>{t(data)}</span>;
			},
		},
		{
			title: t('Invoice email'),
			dataIndex: 'invoice_email',
			width: 180,
		},
		{
			title: t('Email'),
			dataIndex: 'email',
			width: 180,
		},
		{
			title: t('Phone no.'),
			dataIndex: 'mobile',
			width: 180,
		},
		{
			title: t('Application details'),
			dataIndex: 'application_detail',
			width: 180,
			render: (application_detail: any) => {
				if (!application_detail) return '-';
				const filteredOptionsWithPrice0 = application_detail.filter(
					(item: any) => item.price !== 0
				);
				const titles = filteredOptionsWithPrice0.map((detail: any) => {
					return detail?.title;
				});
				return <span>{titles.join(', ')}</span>;
			},
		},
		{
			title: t('Order date'),
			dataIndex: 'CreatedAt',
			width: 180,
			render: (data: any) => {
				return data
					? dayjs(getTimestampInMillisecond(data)).format('YYYY年MM月DD日')
					: '-';
			},
		},
		{
			title: t('Project Publish Estimated Date'),
			dataIndex: 'project_publish_estimate_date',
			width: 180,
			render: (data: string) => {
				return data ? dayjs(data).format('YYYY年MM月DD日') : '-';
			},
		},
		{
			title: t('Last Updated Date and time'),
			dataIndex: 'updated_at',
			width: 180,
			render: (data: any) => {
				return data ? dayjs(data).format('YYYY年MM月DD日 HH:mm') : '-';
			},
		},
		{
			title: t('Completed date'),
			dataIndex: 'completed_at',
			width: 180,
			render: (data: any) => {
				return data ? dayjs(data).format('YYYY年MM月DD日') : '-';
			},
		},
		{
			title: t('Special instruction'),
			dataIndex: 'special_instruction',
			width: 180,
		},
		{
			title: t('Status management'),
			dataIndex: 'order_id',
			width: '140px',
			fixed: 'right',
			align: 'center',
			render: (order_id: string, record: any) => {
				return !record?.seeded_order ? (
					<a
						href={`/status-management/${order_id}`}
						target={'_blank'}
						style={{ textDecoration: 'underline' }}
					>
						{t('Go to status management')}
					</a>
				) : (
					<a
						style={{
							textDecoration: 'underline',
							cursor: 'not-allowed',
							color: 'gray',
						}}
					>
						{t('Go to status management')}
					</a>
				);
			},
		},
		{
			title: t('Remarks'),
			dataIndex: 'remarks',
			fixed: 'right',
			width: 180,
			align: 'center',
			render: (remarks: string, data: any) => {
				return (
					<>
						{!remarks ? (
							<a
								onClick={() => {
									setShowModal(true);
									setRemarksDetail({ id: data.id, remarks: '' });
								}}
							>
								<span title={'アクション'} className={'icon-style'}>
									<img
										src={'/assets/icons/edit-icon.svg'}
										className={'action-svg'}
									/>
								</span>
							</a>
						) : (
							<div className={'remark-text-wrapper'}>
								<p className={'remark-text'}>
									<Tooltip title={remarks}>{remarks}</Tooltip>
								</p>
								<span
									className={'colored-text'}
									onClick={(event) => {
										event.stopPropagation();
										setShowModal(true);
										setRemarksDetail({ id: data.id, remarks: remarks });
									}}
								>
									{'もっと見る'}
								</span>
							</div>
						)}
					</>
				);
			},
		},
	];

	const closeModal = () => {
		setShowModal(false);
	};

	const handlePageChange = async (page: number) => {
		navigate(`/orders?page=${page}`);
	};

	return (
		<>
			<Container>
				{/* <SearchBox /> */}
				<form onSubmit={formik.handleSubmit}>
					<div className={'form-section'}>
						<div className={'left-section'}>
							<InputContainer>
								<Label>{t('Corporate name')}</Label>
								<TextField
									name={'corporate_name'}
									onChange={formik.handleChange}
									// label={'Corporate name'}
									width={'336px'}
									placeholder={'法人名から検索'}
									value={formik.values.corporate_name}
								/>
							</InputContainer>
							<InputContainer>
								<Label>{t('Name')}</Label>
								<TextField
									name={'full_name'}
									onChange={formik.handleChange}
									// label={'Name'}
									width={'336px'}
									placeholder={'氏名、請求名から検索'}
									value={formik.values.full_name}
								/>
							</InputContainer>
						</div>
						<div className={'right-section'}>
							<InputContainer>
								<Label2>{t('Email')}</Label2>
								<TextField
									name={'email'}
									onChange={formik.handleChange}
									// label={'Email'}
									width={'336px'}
									placeholder={'メールアドレス、請求メールアドレスから検索'}
									value={formik.values.email}
								/>
							</InputContainer>
							<InputContainer>
								<Label2>{t('Status')}</Label2>
								<SelectComponent
									onChange={(value: string) => {
										formik.setFieldValue('status', value);
									}}
									// label={t('Status')}
									label={''}
									width={'336px'}
									placeholder={'ステータスから検索'}
									options={ORDERS_STATUS_OPTIONS}
									value={formik.values.status}
								/>
							</InputContainer>
						</div>
					</div>
					<div className={'btn-wrapper'}>
						<ButtonComponent
							htmlType={'submit'}
							type={'medium'}
							width={'112px'}
							background={theme?.buttonColor}
						>
							{' 検索'}
						</ButtonComponent>
						<ButtonComponent
							onClick={() => {
								mutate('1');
								localStorage.removeItem('order-formik-values');
								formik.resetForm();
							}}
							type={'medium'}
							width={'112px'}
							background={'none'}
							color={theme.black}
						>
							{'リセット'}
						</ButtonComponent>
					</div>
				</form>
			</Container>
			<TableContainer>
				<Table
					isSearch={
						!!Object.values(formik.values).filter((obj) => !!obj)?.length &&
						!tableData?.data?.length
					}
					columns={columns}
					dataSource={tableData?.data}
					loading={isLoading || loadingAlgoliaData}
					scrollX={1500}
					pagination={{
						current: Number(page) || 1,
						total: tableData?.count,
						pageSize: DEFAULT_PAGE_SIZE,
						onChange: handlePageChange,
						showSizeChanger: false,
						showTotal: (_, range) => {
							return (
								<p>
									{tableData?.count}
									{'件, '}
									{`${range[0]} ~ ${range[1]}`} {'件名を表示'}
								</p>
							);
						},
					}}
				/>
			</TableContainer>
			<Popup
				title={'備考'}
				popupOpen={showModal}
				onClose={closeModal}
				width={734}
			>
				<RemarkModal
					detail={remarksDetail}
					closeModal={closeModal}
					refetch={() => mutate(page)}
				/>
			</Popup>
		</>
	);
};
