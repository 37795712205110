/* eslint-disable no-console */
import { DeleteOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { FieldArray, FieldArrayRenderProps, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { getDynamicFieldErrorMessage } from '../../../utils/getDynamicFieldErrorMessage';
import { ButtonComponent, TextField } from '../../atoms';
import {
	ErrorCounterWrapper,
	ErrorStyled,
	FieldWrapper,
	FormWrapper,
	Wrapper,
} from '../statusCommonStyles';
import {
	IDesignData,
	IUrl,
	handleCreateNewForm,
	handleFieldLimit,
	isLastIndex,
} from './helpers';
import { checkDisabledField } from '../../../utils';

export const StatusDesign: React.FC<IDesignData> = ({
	formik,
	isLoading,
	savedData,
}) => {
	const handleDeleteDesign = (
		arrayHelpers: FieldArrayRenderProps,
		i: number
	) => {
		arrayHelpers.remove(i);
	};

	const { t } = useTranslation();

	return (
		<Wrapper>
			<h3>{t('デザインデータURL')}</h3>
			<FormWrapper>
				<form onSubmit={formik.handleSubmit}>
					<FormikProvider value={formik}>
						<FieldArray
							name={'designDataUrls'}
							validateOnChange={true}
							render={(arrayHelpers) => {
								return (
									<div>
										{Boolean(
											arrayHelpers.form.values?.designDataUrls &&
												arrayHelpers.form.values?.designDataUrls.length > 0 &&
												Array.isArray(arrayHelpers.form.values.designDataUrls)
										) &&
											arrayHelpers.form.values.designDataUrls.map(
												(value: IUrl, i: number) => {
													return (
														<FieldWrapper key={i}>
															<div className={'field-and-button-wrapper'}>
																<TextField
																	fullWidth={true}
																	width={'100%'}
																	height={'42px'}
																	className={'text-field'}
																	name={`designDataUrls.${i}.url`}
																	placeholder={'入力ください'}
																	value={value.url}
																	error={
																		getDynamicFieldErrorMessage(
																			'designDataUrls',
																			'url',
																			i,
																			formik
																		)
																			? true
																			: false
																	}
																	onChange={formik.handleChange}
																	onBlur={formik.handleBlur}
																/>
																{isLastIndex(i, formik) ? (
																	<div className={'action-button-wrapper'}>
																		<ButtonComponent
																			type={'small'}
																			borderradius={'5px'}
																			width={'36px'}
																			height={'32px'}
																			disabled={handleFieldLimit(formik)}
																			onClick={() =>
																				handleCreateNewForm(arrayHelpers, i + 1)
																			}
																		>
																			{'+'}
																		</ButtonComponent>
																	</div>
																) : (
																	<div className={'action-button-wrapper'}>
																		<DeleteOutlined
																			className={'delete-icon'}
																			onClick={() =>
																				handleDeleteDesign(arrayHelpers, i)
																			}
																		/>
																	</div>
																)}
															</div>
															{getDynamicFieldErrorMessage(
																'designDataUrls',
																'url',
																i,
																formik
															) && (
																<ErrorCounterWrapper>
																	<ErrorStyled>
																		{getDynamicFieldErrorMessage(
																			'designDataUrls',
																			'url',
																			i,
																			formik
																		)}
																	</ErrorStyled>
																</ErrorCounterWrapper>
															)}
														</FieldWrapper>
													);
												}
											)}
									</div>
								);
							}}
						/>
					</FormikProvider>
					<Divider className={'form-divider'} />
					<div className={'save-btn-wrapper'}>
						<ButtonComponent
							type={'large'}
							borderradius={'62px'}
							width={'200px'}
							fontSize={'16px'}
							bold={true}
							backgroundColorWhenDisabled={'transparent'}
							background={theme?.red}
							disabled={checkDisabledField(
								formik.values?.designDataUrls,
								savedData
							)}
							loading={isLoading}
							htmlType={'submit'}
						>
							{'保存して次のタブへ'}
						</ButtonComponent>
					</div>
				</form>
			</FormWrapper>
		</Wrapper>
	);
};
