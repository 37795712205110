import styled from 'styled-components';
import { theme } from '../../../theme';

export const Container = styled.div`
	display: flex;
	padding-bottom: 20px;
	flex-direction: column;
	.key-status {
		font-weight: 700;
		font-size: 18px;
		padding-bottom: 20px;
	}
	.slick-slider {
		padding-top: 20px;
		width: 760px;
		margin: 0 auto;
		margin-top: 20px;
		img {
			width: 90%;
			height: 344px;
			object-fit: cover;
			margin: auto;
		}
		.anticon svg {
			color: ${theme.black};
			font-size: 25px;
		}
		.slick-dots li button:before {
			font-size: 12px;
			line-height: 50px;
		}
	}
	.image-list {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;
		margin-top: 40px;
		overflow-x: scroll;
		::-webkit-scrollbar {
			display: none;
		}
		.ant-image-mask-info,
		.anticon svg {
			display: flex;
			align-items: center;
			font-size: 13px;
		}
		.ant-image-mask-info,
		.anticon svg {
			margin-top: 2px;
		}
		.image-style {
			width: 130px;
			height: 160px;
			object-fit: cover;
		}
	}
`;
