export const getDynamicFieldErrorMessage = (
	objectName: string,
	fieldName: string,
	index: number,
	formik: any,
	objectName1?: string
): string | boolean => {
	const haveError =
		Array.isArray(
			objectName1
				? formik.errors[objectName]?.[objectName1]
				: formik.errors[objectName]
		) &&
		Boolean(
			objectName1
				? formik.errors[objectName]?.[objectName1]?.[index] &&
						formik.errors[objectName]?.[objectName1]?.[index]?.[fieldName]
				: formik.errors[objectName][index] &&
						formik.errors[objectName][index][fieldName]
		);

	const isTouched =
		Array.isArray(
			objectName1
				? formik.touched[objectName]?.[objectName1]
				: formik.touched[objectName]
		) &&
		Boolean(
			objectName1
				? formik.touched[objectName]?.[objectName1]?.[index] &&
						formik.touched[objectName]?.[objectName1]?.[index]?.[fieldName]
				: formik.touched[objectName][index] &&
						formik.touched[objectName][index][fieldName]
		);

	if (haveError && isTouched) {
		if (objectName1) {
			return formik.errors[objectName][objectName1][index][fieldName];
		}
		return formik.errors[objectName][index][fieldName];
	}

	return false;
};
