import React, { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { auth } from './firebase';
import { styled } from 'styled-components';

type ContextProps = {
	loading: boolean;
	user: User | null;
	authenticated: boolean;
	role: string;
	// eslint-disable-next-line no-unused-vars
	setUser: (user: User | null) => void;
	logout: () => void;
};

const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;

export const AuthContext = React.createContext<ContextProps>(
	{} as ContextProps
);

export const AuthProvider = ({ children }: any) => {
	const [user, setUser] = useState<User | null>(null);
	const [role, setRole] = useState('');
	const [authenticated, setAuthenticated] = useState(false);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	const initialLoad = () => {
		auth.onAuthStateChanged(async (user: any) => {
			if (user !== null) {
				const idTokenResult = await user.getIdTokenResult();
				const role = idTokenResult.claims.role;
				setRole(role);
				setAuthenticated(true);
				setUser(user);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		initialLoad();
	});
	if (loading) {
		return (
			<LoaderWrapper>
				<Spin size={'large'} spinning={loading} />
			</LoaderWrapper>
		);
	}

	const logout = async () => {
		await auth.signOut();
		setLoading(true);
		setAuthenticated(false);
		setRole('');
		setUser(null);
		setLoading(false);
		navigate('/');
	};

	return (
		<AuthContext.Provider
			value={{
				loading,
				user,
				authenticated,
				setUser,
				logout,
				role,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
