/* eslint-disable no-console */
import dayjs from 'dayjs';
import { t } from 'i18next';
import { theme } from '../../../theme';
import { CustomDatePicker } from '../../atoms/CustomDatePicker';
import { IScheduleProps } from './helper';
import { CustomButton, Wrapper } from './styles';

const ScheduleView: React.FC<IScheduleProps> = ({ formik, isLoading }) => {
	return (
		<form onSubmit={formik.handleSubmit}>
			<Wrapper>
				<div className={'date-picker-container'}>
					<div className={'title'}>{t('Schedule')}</div>
					<CustomDatePicker
						name={'first_application'}
						label={'First application'}
						value={
							formik.values.first_application
								? dayjs(formik.values.first_application)
								: undefined
						}
						onChange={(value: any) => {
							formik.setFieldValue('first_application', value);
						}}
					/>
					<CustomDatePicker
						name={'last_application'}
						label={'Last application'}
						value={
							formik.values.last_application
								? dayjs(formik.values.last_application)
								: undefined
						}
						onChange={(value: any) => {
							formik.setFieldValue('last_application', value);
						}}
					/>
					<CustomDatePicker
						name={'project_publish'}
						label={'Project publish'}
						value={
							formik.values.project_publish
								? dayjs(formik.values.project_publish)
								: undefined
						}
						onChange={(value: any) => {
							formik.setFieldValue('project_publish', value);
						}}
					/>
					<CustomDatePicker
						name={'project_end'}
						label={'Project end'}
						value={
							formik.values.project_end
								? dayjs(formik.values.project_end)
								: undefined
						}
						onChange={(value: any) => {
							formik.setFieldValue('project_end', value);
						}}
					/>
				</div>
				<div className={'button-group'}>
					<CustomButton
						type={'large'}
						borderradius={'62px'}
						width={'200px'}
						fontSize={'16px'}
						bold={true}
						backgroundColorWhenDisabled={'transparent'}
						background={theme?.red}
						disabled={!formik.dirty}
						htmlType={'submit'}
						loading={isLoading}
					>
						{t('Save and go to next tab')}
					</CustomButton>
				</div>
			</Wrapper>
		</form>
	);
};

export { ScheduleView };
