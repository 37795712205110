import axios from 'axios';
import { auth } from './firebase';

axios.defaults.responseType = 'json';

const API = axios.create({
	baseURL: process.env.REACT_APP_PUBLIC_API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Time-Zone': `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
		'Access-Control-Allow-Origin': origin,
	},
});

API.interceptors.request.use(
	async (config) => {
		const token = await auth.currentUser?.getIdToken(true);
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

API.interceptors.response.use(
	(response) => {
		return response?.data;
	},
	(error) => {
		if (error?.response?.status === 401) {
			auth.signOut();
		}
		return Promise.reject(error.response);
	}
);
export { API };
