import React from 'react';
import { TextEditor, TextField } from '../../atoms';
import { CommentComponent } from '../CommentComponent';
import { t } from 'i18next';
import { Title } from '../RiskChallenges/styles';
import styled from 'styled-components';

interface storyProps {
	formik: any;
}

const Spacer = styled.div`
	padding-bottom: 28px;
`;
const Wrapper = styled.div`
	.label {
		min-width: 75px;
	}
`;

export const Story: React.FC<storyProps> = ({ formik }) => {
	return (
		<Wrapper>
			<Title>{t('Story')}</Title>
			<Spacer />
			<TextField
				fullWidth={true}
				width={'100%'}
				height={'42px'}
				className={'text-field'}
				name={'point1'}
				onChange={(e) => {
					formik.setFieldValue('story.point1', e.target.value);
				}}
				onBlur={formik.handleBlur}
				value={formik.values?.story?.point1}
				showCounting={true}
				maxLength={47}
				label={'Point 1'}
			/>
			<Spacer />
			<TextField
				fullWidth={true}
				width={'100%'}
				height={'42px'}
				className={'text-field'}
				name={`point2`}
				onBlur={formik.handleBlur}
				value={formik.values?.story?.point2}
				showCounting={true}
				maxLength={47}
				onChange={(e) => {
					formik.setFieldValue('story.point2', e.target.value);
				}}
				label={'Point 2'}
			/>
			<Spacer />
			<TextField
				fullWidth={true}
				width={'100%'}
				height={'46px'}
				className={'text-field'}
				name={`point3`}
				onBlur={formik.handleBlur}
				value={formik.values?.story?.point3}
				showCounting={true}
				maxLength={47}
				label={'Point 3'}
				onChange={(e) => {
					formik.setFieldValue('story.point3', e.target.value);
				}}
			/>
			<Spacer />
			<TextEditor
				data={formik.values?.story?.rich_text_data}
				handleData={(value: any) => {
					formik.setFieldValue('story.rich_text_data', value);
				}}
				error={formik.touched.description && formik.errors.description}
			/>

			<CommentComponent
				commentType={'story'}
				formik={formik}
				title={t('Story Comments')}
				commentData={formik.initialValues?.story?.comments}
			/>
		</Wrapper>
	);
};
