import { styled } from 'styled-components';
import { t } from 'i18next';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;
const NotFound = () => {
	return (
		<Wrapper>
			<div>
				{'404:'}
				{t('This page could not be found')}
			</div>
		</Wrapper>
	);
};

export default NotFound;
