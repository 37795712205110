import { Spin } from 'antd';
import { t } from 'i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/molecules/PageHeader';
import { TabBar } from '../../components/molecules/Tabbar';
import { LoaderWrapper } from '../../components/organisms/statusCommonStyles';
import { getStatus } from '../../services/status';
import { MainContainer } from '../../components/molecules/Tabbar/styles';
import { useState } from 'react';
import NotFound from '../NotFound';

const StatusManagement = () => {
	const { orderID } = useParams();
	const [isError, setError] = useState(false);
	const {
		data: statusData,
		isLoading,
		refetch,
	} = useQuery(['get-status', orderID], getStatus, {
		retry: false,
		onError: () => setError(true),
	});

	if (isLoading) {
		return (
			<LoaderWrapper>
				<Spin size={'large'} spinning={isLoading} />
			</LoaderWrapper>
		);
	}
	if (isError) {
		return <NotFound />;
	}

	return (
		<MainContainer>
			<PageHeader
				title={`${t('Order No.')}${orderID}${t('Status management')}`}
			/>
			<TabBar statusData={statusData} refetchStatus={refetch} />
		</MainContainer>
	);
};

export default StatusManagement;
