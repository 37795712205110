/* eslint-disable no-console */
import { API } from '../api';

interface IUserParams {
	page: number;
	pageSize?: number;
}

export const getAllUsers = async (pageNumber: string | null): Promise<any> => {
	const params = {} as IUserParams;
	params.page = pageNumber ? Number(pageNumber) : 1;
	params.pageSize = 10;
	return API.get('/users', { params });
};
