import { Modal } from 'antd';
import { styled } from 'styled-components';

export const StyledModal = styled(Modal)<any>`
	.ant-modal .ant-modal-body {
		font-size: 14px;
		line-height: 1.5714285714285714;
		word-wrap: break-word;
		min-height: 24px;
	}

	.ant-modal .ant-modal-content {
		position: relative;
		background-color: #ffffff;
		background-clip: padding-box;
		border: 0;
		border-radius: 14px;
		box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
			0 9px 28px 8px rgb(0 0 0 / 5%);
		pointer-events: auto;
		min-height: 24px;
	}

	.ant-modal-content {
		width: 100%;
		padding: ${({ padding }: any) =>
			padding ? padding : '30px 30px 42px 30px'};
	}

	img {
		cursor: pointer;
	}
`;

export const PopupHeader = styled.div`
	.header-main {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		/* padding: 20px 0px; */
	}

	.title {
		font-weight: 500;
		font-size: 16px;
		line-height: 23px;
	}

	svg {
		width: 21px;
		height: 21px;
	}

	.divider {
		margin: 20px 0px;
		background: #dedbd5;
	}
`;
