/* eslint-disable no-console */
import algoliasearch from 'algoliasearch';

interface IAlgoliaSearchProps {
	full_name?: string;
	corporate_name?: string;
	email?: string;
	status?: string;
	[s: string]: unknown;
}

const useAlgoliaSearch = async (
	queryParams: IAlgoliaSearchProps,
	page?: string | null
) => {
	const algoliaClient = algoliasearch(
		process.env.REACT_APP_ALGOLIA_APPLICATION_ID || '',
		process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY || ''
	);

	const searchIndexCondition = Object.keys(queryParams).includes('status');

	const algoliaIndex = algoliaClient.initIndex(
		searchIndexCondition ? 'orders' : 'users'
	);

	const newArray: string[] = [];
	Object.keys(queryParams)
		.filter((item) => {
			if (item) {
				return queryParams[item];
			}
		})
		?.map((item: string) => {
			item === 'email'
				? newArray.push(...['invoice_email', 'email'])
				: item === 'full_name'
				? newArray.push(...['invoice_name', 'full_name'])
				: newArray.push(item);
		});

	console.log(newArray);

	const searchParams = Object.values(queryParams)
		.filter((item) => {
			return !!item;
		})
		.join(',');

	const responseArrayOfObjects = await algoliaIndex
		.search(searchParams, {
			page: page ? Number(page) - 1 : 0,
			restrictSearchableAttributes: newArray,
		})
		.then(({ hits, nbHits }) => {
			const hitsUpdatedArray = hits?.map((item: any, index: any) => ({
				...item,
				key: index,
			}));
			return { hitsUpdatedArray, nbHits };
		});

	return {
		data: responseArrayOfObjects?.hitsUpdatedArray,
		count: responseArrayOfObjects?.nbHits,
	};
};

export { useAlgoliaSearch };
