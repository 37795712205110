import styled from 'styled-components';
import { theme } from '../../../theme';
import { ButtonComponent } from '../../atoms';

export const Wrapper = styled.div`
	height: 582px;
	width: 100%;
	background-color: ${theme.white};
	display: flex;
	flex-direction: column;
	.date-picker-container {
		padding: 28px 120px 28px 28px;
		display: flex;
		flex-direction: column;
		row-gap: 14px;
		border-bottom: 1px solid ${theme.borderBGColor};
		.title {
			font-size: 18px;
			font-weight: 700;
			line-height: 27px;
			color: ${theme.black};
			margin-bottom: 10px;
		}
	}
	.button-group {
		margin-top: 28px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}
`;

export const CustomButton = styled(ButtonComponent)``;
