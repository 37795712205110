import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { EyeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Container } from './image-container.styles';
import { Image, Modal } from 'antd';
import { StatusImageUpload } from '../../atoms';

interface IImageContainerProps {
	title: string;
	handleChange: any;
	carouselImages?: boolean;
	defaultRatio?: boolean;
	imageList: string[];
	errorMessage: any;
	showUploadButton?: boolean;
	alertText?: string;
	disableUpload?: boolean;
}

const ImageContainer: React.FC<IImageContainerProps> = ({
	title,
	handleChange,
	carouselImages = true,
	defaultRatio = true,
	imageList,
	errorMessage,
	showUploadButton = true,
	alertText,
	disableUpload,
}) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);
	const sliderRef: any = useRef(null);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,

		prevArrow: <LeftOutlined />,
		nextArrow: <RightOutlined />,
	};

	const modalSettings = {
		lazyRender: true,
		dots: true,
		infinite: true,
		autoplay: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <LeftOutlined />,
		nextArrow: <RightOutlined />,
	};

	const handleCLick = (index: number) => {
		setVisible(true);
		setTimeout(() => sliderRef?.current?.slickGoTo(index), 50);
	};

	return (
		<Container>
			<span className={'key-status'}>{t(title)}</span>
			{showUploadButton && (
				<StatusImageUpload
					handleChange={handleChange}
					defaultRatio={defaultRatio}
					errorMessage={errorMessage}
					totalNumberOfImages={imageList?.length}
					alertMessage={alertText}
					disableUpload={disableUpload}
				/>
			)}
			{!!imageList?.length &&
				(carouselImages ? (
					<Slider {...settings}>
						{imageList?.map((item, index) => {
							return (
								<div key={index}>
									<img src={item} alt={'carousel-image'} />
								</div>
							);
						})}
					</Slider>
				) : (
					<>
						<div className={'image-list'}>
							{imageList?.map((item, index) => {
								return (
									<div key={index}>
										<Image
											src={item}
											alt={'image-list'}
											onClick={() => handleCLick(index)}
											className={'image-style'}
											preview={{
												visible: false,
												onVisibleChange: (value) => {
													setVisible(value);
												},
												mask: (
													<span>
														<EyeOutlined />
														&nbsp;
														{t('Preview')}
													</span>
												),
											}}
										/>
									</div>
								);
							})}
						</div>

						<Modal
							open={visible}
							onCancel={() => setVisible(false)}
							footer={false}
							className={'image-previewer-modal'}
						>
							<Slider {...modalSettings} ref={sliderRef}>
								{imageList?.map((item, index) => {
									return (
										<img
											className={'image-style'}
											key={index}
											src={item}
											alt={'image-list'}
										/>
									);
								})}
							</Slider>
						</Modal>
					</>
				))}
		</Container>
	);
};

export { ImageContainer };
