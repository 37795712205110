import styled from 'styled-components';
import { theme } from '../../../theme';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 5px;
`;
export const CommentWrapper = styled.div<any>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	.label {
		color: ${theme.label};
		margin-right: 36px;
		font-weight: 400;
		margin-bottom: 5px;
		max-width: 82px;
		width: 100%;
		font-size: 16px;
		${theme.typography.typographySmall}
	}
	.messageContainer {
		background-color: ${({ bgColor }) =>
			bgColor ? bgColor : `${theme.commentBGColor}`};
		border: 1px solid
			${({ borderColor }) =>
				borderColor ? borderColor : `${theme.commentBGColor}`};
		border-radius: 8px;
		padding: 12px 16px;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.36px;
		color: ${theme.black};
		width: 100%;
	}
`;
export const DateLabel = styled.div`
	font-size: 16px;
	font-weight: 400;
	display: flex;
	justify-content: end;
	align-items: center;
	color: ${theme.black};
`;
