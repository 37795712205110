import { useMutation } from 'react-query';
import { getAllUsers } from '../../../services/users';
import { t } from 'i18next';
import { Container, InputContainer, TableContainer } from './styles';
import { Table } from '../../molecules';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { TextField } from '../../atoms/TextField';
import { ButtonComponent } from '../../atoms';
import { theme } from '../../../theme';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { getTimestampInMillisecond, useAlgoliaSearch } from '../../../utils';

export const UserListTable = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [loadingAlgoliaData, setLoadingAlgoliaData] = useState(false);
	const [tableData, setTableData] = useState({ data: [], count: 0 });
	const page = searchParams.get('page');

	const [queryParams] = useState({
		full_name: '',
		corporate_name: '',
		email: '',
	});
	const formik = useFormik({
		initialValues: queryParams,
		onSubmit: async (values) => {
			if (Object.values(values).filter((item) => !!item)?.length) {
				localStorage.setItem(
					'users-formik-values',
					JSON.stringify(formik.values)
				);
				setLoadingAlgoliaData(true);
				const searchedData: any = await useAlgoliaSearch(values, page);
				setLoadingAlgoliaData(false);
				if (searchedData?.count <= 10 && Number(page) !== 1) {
					navigate('/users?page=1');
				}
				setTableData(searchedData);
			} else {
				mutate('1');
				localStorage.removeItem('users-formik-values');
			}
		},
	});

	const { mutate, isLoading } = useMutation(['getAllUsers'], getAllUsers, {
		onSuccess: ({ data, count }) => {
			const dataWithKey = {
				data: data?.map((item: any, index: any) => ({ ...item, key: index })),
				count: count,
			};
			setTableData(dataWithKey);
		},
	});

	const columns = [
		{
			title: t('Corporate name'),
			dataIndex: 'corporate_name',
		},
		{
			title: t('Full name'),
			dataIndex: 'full_name',
		},
		{
			title: t('Email'),
			dataIndex: 'email',
		},
		{
			title: t('Invoice email'),
			dataIndex: 'invoice_email',
			render: (email: string) => {
				return email ? email : '-';
			},
		},
		{
			title: t('Invoice name'),
			dataIndex: 'invoice_name',
			render: (name: string) => {
				return name ? name : '-';
			},
		},
		{
			title: t('Phone number'),
			dataIndex: 'mobile',
			render: (mobile: string) => {
				return mobile ? mobile : '-';
			},
		},
		{
			title: t('Cloud fund email'),
			dataIndex: 'crowd_funding_email',
		},
		{
			title: t('Created at'),
			dataIndex: 'CreatedAt',
			render: (CreatedAt: any) => {
				return CreatedAt
					? dayjs(getTimestampInMillisecond(CreatedAt)).format('YYYY年MM月DD日')
					: '-';
			},
		},
	];

	useEffect(() => {
		(async () => {
			const formikValuesFromLocalStorage = localStorage.getItem(
				'users-formik-values'
			);
			const parsedValue =
				formikValuesFromLocalStorage &&
				JSON.parse(formikValuesFromLocalStorage);

			if (parsedValue) {
				formik.setValues(JSON.parse(formikValuesFromLocalStorage));
				setLoadingAlgoliaData(true);
				const searchedData: any = await useAlgoliaSearch(parsedValue, page);
				setTableData(searchedData);
				setLoadingAlgoliaData(false);
			} else {
				mutate(page);
			}
		})();
	}, [page]);

	const handlePageChange = (page: number) => {
		navigate(`/users?page=${page}`);
	};

	return (
		<>
			<Container>
				<form onSubmit={formik.handleSubmit}>
					<div className={'form-section'}>
						<div className={'left-section'}>
							<InputContainer>
								<TextField
									name={'full_name'}
									onChange={formik.handleChange}
									label={'Full name'}
									width={'336px'}
									placeholder={'氏名、請求名から検索'}
									value={formik.values.full_name}
								/>
							</InputContainer>
							<InputContainer>
								<TextField
									name={'corporate_name'}
									onChange={formik.handleChange}
									label={'Corporate name'}
									width={'336px'}
									placeholder={'法人名から検索'}
									value={formik.values.corporate_name}
								/>
							</InputContainer>
						</div>
						<div className={'right-section'}>
							<InputContainer>
								<TextField
									name={'email'}
									onChange={formik.handleChange}
									label={'Email'}
									width={'336px'}
									placeholder={'メールアドレス、請求メールアドレスから検索'}
									value={formik.values.email}
								/>
							</InputContainer>
						</div>
					</div>
					<div className={'btn-wrapper'}>
						<ButtonComponent
							htmlType={'submit'}
							type={'medium'}
							width={'112px'}
							background={theme?.buttonColor}
						>
							{' 検索'}
						</ButtonComponent>
						<ButtonComponent
							onClick={() => {
								mutate('1');
								localStorage.removeItem('users-formik-values');
								formik.resetForm();
							}}
							type={'medium'}
							width={'112px'}
							background={'none'}
							color={theme.black}
						>
							{'リセット'}
						</ButtonComponent>
					</div>
				</form>
			</Container>
			<TableContainer>
				<Table
					isSearch={
						!!Object.values(formik.values).filter((obj) => !!obj)?.length &&
						!tableData?.data?.length
					}
					columns={columns}
					dataSource={tableData?.data || []}
					loading={isLoading || loadingAlgoliaData}
					pagination={{
						current: Number(page) || 1,
						total: tableData?.count || 0,
						pageSize: DEFAULT_PAGE_SIZE,
						onChange: handlePageChange,
						showSizeChanger: false,
						showTotal: (_, range) => {
							return (
								<>
									{tableData?.count}
									{'件, '}
									{`${range[0]} ~ ${range[1]}`} {'件名を表示'}
								</>
							);
						},
					}}
				/>
			</TableContainer>
		</>
	);
};
