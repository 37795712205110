import { LoadingOutlined } from '@ant-design/icons';
import { Upload, notification } from 'antd';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { storage } from '../../../firebase';
import { theme } from '../../../theme';
import { ButtonComponent } from '../Button';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const UploadWrapper = styled.div`
	display: flex;
	align-items: center;
	.button-title {
		font-weight: 400;
		font-size: 14px;
	}
`;

export const Error = styled.div<any>`
	/* margin-left: 2em; */
	color: ${theme.red};
	font-size: 12px;
`;

interface Props {
	handleChange: any;
	name?: string;
	setLoading?: any;
	setImgUploadError?: any;
	setUploading?: any;
	imgUploadError?: boolean;
	defaultRatio?: boolean;
	errorMessage?: any;
	fromTextEditor?: boolean;
	totalNumberOfImages?: any;
	alertMessage?: string;
	disableUpload?: boolean;
}

const StatusImageUpload: React.FC<Props> = ({
	handleChange,
	defaultRatio = true,
	totalNumberOfImages,
	errorMessage,
	fromTextEditor = false,
	alertMessage,
	disableUpload,
}: Props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const error = t('*Up to 3 images with ratio of 16:9');

	useEffect(() => {
		if (errorMessage) {
			displayErrorForAWhile(errorMessage);
		}
	}, [errorMessage]);

	const onRemove = async () => {
		const imageFile = {
			imageFile: '',
			imagePreviewUrl: '',
		};
		handleChange(imageFile);
	};
	const displayErrorForAWhile = (error: any) => {
		notification.error({ message: error });
	};

	const beforeUpload = (file: any) => {
		return new Promise((resolve, reject) => {
			const isValidFile = ['image/jpeg', 'image/png', 'image/png'].includes(
				file?.type
			);
			const isLessThanFiveMB = file.size / 1024 / 1024 <= 5;
			if (!isValidFile || !isLessThanFiveMB) {
				displayErrorForAWhile(
					t('Only jpg, png, jpeg format image within 5MB can be uploaded.')
				);
				reject(false);
			}
			if (isValidFile && isLessThanFiveMB) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (e: ProgressEvent<any>) => {
					const image: any = document.createElement('img');
					image.src = e.target.result;
					image.onload = function () {
						const height = this.height;
						const width = this.width;
						const aspectratio = defaultRatio ? 16 / 9 : 9 / 16;
						const imageRatio = Math.abs(width / height - aspectratio) < 0.01;
						if (!defaultRatio) {
							resolve(true);
						} else if (defaultRatio && imageRatio) {
							resolve(true);
						} else {
							displayErrorForAWhile(error);
							reject(false);
						}
					};
				};
			}
		});
	};

	const uploadToStorage = async (file: any) => {
		if (file) {
			const storageRef = ref(storage, `status-management-images/${file?.name}`);
			await uploadBytes(storageRef, file);
			return getDownloadURL(storageRef);
		}
	};

	const handleUpload = async ({ file, onSuccess, onError }: any) => {
		try {
			setLoading?.(true);
			const image: any = await uploadToStorage(file);
			handleChange(image);
			onSuccess?.('ok');
		} catch (err: any) {
			onError?.(err);
		} finally {
			setLoading?.(false);
		}
	};

	return (
		<Container>
			<UploadWrapper>
				<Upload
					accept={'image/*'}
					beforeUpload={(file: any): any => {
						if (!fromTextEditor) {
							const condition = defaultRatio
								? totalNumberOfImages < 3
								: totalNumberOfImages < 7;
							const error = defaultRatio
								? t('*Up to 3 images')
								: t('*Up to 7 images');
							if (condition) {
								return beforeUpload(file);
							}
							displayErrorForAWhile(error);
							return false;
						}
						return true;
					}}
					customRequest={handleUpload}
					className={'upload-image'}
					onRemove={onRemove}
					disabled={loading || disableUpload}
					showUploadList={false}
				>
					{!fromTextEditor ? (
						<ButtonComponent
							background={'transparent !important'}
							color={'black'}
							height={'43px'}
							padding={'12px'}
							borderradius={'4px'}
							bordercolor={theme?.primary_90}
							loading={loading}
							disabled={loading || disableUpload}
						>
							<span className={'button-title'}>{t('+ Upload Image')}</span>
						</ButtonComponent>
					) : (
						<button type={'button'}>
							<span title={'Add Image'} style={{ position: 'relative' }}>
								{loading ? (
									<LoadingOutlined />
								) : (
									<img src={'/assets/MenuBarIcons/add-picture.svg'} />
								)}
							</span>
						</button>
					)}
				</Upload>
				{/* {!fromTextEditor && defaultRatio && <Error>{error}</Error>} */}
			</UploadWrapper>
			<Error>{t(`*${alertMessage}`)}</Error>
		</Container>
	);
};

export { StatusImageUpload };
