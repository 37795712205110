import { PageHeader } from '../../components/molecules';
import { OrderListForm } from '../../components/organisms/OrderListForm';
import { MainPageWrapper } from '../../utils/global';

const Orders = () => {
	return (
		<>
			<PageHeader title={'Order management'} />
			<MainPageWrapper>
				<OrderListForm />
			</MainPageWrapper>
		</>
	);
};

export default Orders;
