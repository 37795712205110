import { Table } from 'antd';
import { styled } from 'styled-components';
import { theme } from '../../../theme';

export const StyledTable = styled(Table)`
	& .ant-table-thead > tr > th {
		background-color: ${theme.primaryColor};
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
`;
export const Wrapper = styled.div`
	display: contents;
	& .ant-table-thead > tr > th {
		color: ${theme.white};
	}

	& .ant-table-wrapper .ant-table .ant-table-header {
		border-radius: 0px;
	}

	& .ant-table-thead,
	.ant-table-tbody {
		word-break: keep-all;
		white-space: break-spaces;
	}

	& .ant-table-tbody > tr > td {
		border-bottom: 0px;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
	}

	& .ant-table-wrapper .ant-table-thead:not(:last-child) > tr > th {
		/* width: 180px; */
		height: 68px;
	}

	& .ant-table-row:nth-child(even) {
		background-color: #f5f5f5;
	}

	&
		.ant-table-wrapper
		.ant-table-container
		table
		> thead
		> tr:first-child
		> *:first-child {
		border-start-start-radius: 0px;
	}

	&
		.ant-table-wrapper
		.ant-table-container
		table
		> thead
		> tr:first-child
		> *:last-child {
		border-start-end-radius: 0px;
	}

	&
		.ant-table-wrapper
		.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		height: 0rem;
	}

	//Border style for pagination page item when active and not active
	& .ant-pagination .ant-pagination-item {
		border: 1px solid ${theme?.neutral_1500};
	}
	& .ant-pagination .ant-pagination-item-active {
		border: 1px solid ${theme?.primary_600};
	}

	//Pagination item style when active and not active
	& .ant-pagination .ant-pagination-item-active a {
		color: ${theme?.black};
	}
	& .ant-pagination .ant-pagination-item a {
		color: ${theme?.neutral_1200};
	}

	//Border style for pre and next item button and button item alignment center
	& .ant-pagination .ant-pagination-prev,
	.ant-pagination .ant-pagination-next {
		border: 1px solid ${theme?.neutral_1500};

		button {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	& .ant-pagination .ant-pagination-next {
		svg {
			color: ${theme?.neutral_1600};
		}
	}
`;
