const typographyH1 = {
	fontWeight: '500',
	fontSize: '36px',
	fontFamily: 'Noto Sans JP, sans-serif',
};

const typographyH2 = {
	fontWeight: '500',
	fontSize: '32px',
	fontFamily: 'Noto Sans JP, sans-serif',
};

const typographyPageTitle = {
	fontWeight: '500',
	fontSize: '22px',
	fontFamily: 'Noto Sans JP, sans-serif',
};

const typographyCaption = {
	fontWeight: '500',
	fontSize: '18px',
	fontFamily: 'Noto Sans JP, sans-serif',
};

const typographyBody = {
	fontWeight: '500',
	fontSize: '16px',
	fontFamily: 'Noto Sans JP, sans-serif',
};

const typographySmall = {
	fontWeight: '500',
	fontSize: '14px',
	fontFamily: 'Noto Sans JP, sans-serif',
	lineHeight: '20px',
};

const typographyPageSubTitle = {
	fontWeight: '500',
	fontSize: '20px',
	fontFamily: 'Noto Sans JP, sans-serif',
	lineHeight: '28.96px',
};

export const theme = {
	// Primary colors
	primary_1200: '#D0D6E3',
	primary_1100: '#B0BAD0',
	primary_1000: '#8998B8',
	primary_900: '#6276A1',
	primary_800: '#3A5389',
	primary_700: '#133172',
	primary_600: '#133172',
	primary_500: '#10295F',
	primary_400: '#0D214C',
	primary_300: '#0A1939',
	primary_200: '#061026',
	primary_100: '#040A17',
	primary_90: '#025EAE',
	// Secondary colors
	secondary_1200: '#F3F3EE',
	secondary_1100: '#FFEFD2',
	secondary_1000: '#FEE4B4',
	secondary_900: '#FED78F',
	secondary_800: '#FECA6A',
	secondary_700: '#FDBC44',
	secondary_600: '#FDAF1F',
	secondary_500: '#D3921A',
	secondary_400: '#A97515',
	secondary_300: '#7F5810',
	secondary_200: '#543A0A',
	secondary_100: '#332306',
	// Accent colors
	accent_1200: '#F3D2D3',
	accent_1100: '#EBB5B5',
	accent_1000: '#E18F90',
	accent_900: '#D86A6B',
	accent_800: '#CE4546',
	accent_700: '#C42021',
	accent_600: '#C42021',
	accent_500: '#A31B1C',
	accent_400: '#831516',
	accent_300: '#621011',
	accent_200: '#410B0B',
	accent_100: '#270607',
	// Neutral colors

	neutral_1600: '#595959',
	neutral_1500: '#d9d9d9',
	neutral_1400: '#dcdcdc',
	neutral_1300: '#ababab',
	neutral_1200: '#333333',
	neutral_1000: '#656565',
	neutral_1100: '#030A26',
	neutral_900: '#c6d1e9',
	neutral_800: '#626262',
	neutral_700: '#e6e6e6',
	neutral_600: '#3E3E3E',
	neutral_500: '#9E9E9E',
	neutral_400: '#DEDEDE',
	neutral_300: '#F3F3F3',
	placeholder: '#3E3E3E99',
	card_border: '#D6D6D6',
	neutral_200: '#F3F4F6',
	neutral_100: '#FFFFFF',
	red: '#FB446C',
	blue: '0000FF',
	buttonColorTabContents: 'rgba(251, 68, 108, 0.3)',

	//grey
	grey: '#DBDBDB',
	background_field: '#FBFBFB',
	lightGrey: '#F1F2F5',
	black: '#000',
	label: '#3E3E3E',
	borderColor: '#162146',
	borderBgColor: '#DEDBD5',
	greyPlaceHolder: '#959292',

	//white color
	white: '#FFFFFF',
	coldWhite: '#F5F8FF',

	background: '#F4F6FB',
	borderBGColor: '#DEDBD5',

	scrollbar_track: '#ededed',
	scrollbar_thumb: '#c6c6c6',

	darkGrey: '#AAAAAA',
	darkBlue: '#5C6E97',
	lightBlue: '#1677ff',

	buttonColor: '#FB446C',
	primaryColor: '#373A99',
	commentBorderColor: '#74C1EF',
	commentBoderColor1: '#F36B86',
	commentBGColor: '#EEF9FF',
	commentBGColor1: '#FEE6EB',
	commentOuterBorder: '#DEDBD5',
	dropdowniconColor: '#262626',
	errorColor: '#ff4d4f',
	countColor: '#000000B2',
	typography: {
		typographyH1,
		typographyH2,
		typographyPageTitle,
		typographyCaption,
		typographyBody,
		typographySmall,
		typographyPageSubTitle,
	},
};
