import { Input } from 'antd';
import React, { useCallback } from 'react';
import { CharacterCounter } from '../../../utils';
import {
	CountingStyled,
	ErrorCounterWrapper,
	ErrorStyled,
	InputStyled,
	TextFieldWrapperStyled,
	Wrapper,
} from './styles';
import { TextFieldProps } from './types';
import { t } from 'i18next';

const TextField: React.FC<TextFieldProps> = React.forwardRef((props, ref) => {
	const {
		showCounting,
		value,
		maxLength,
		type,
		rows,
		className,
		label,
		required,
		prefix,
		error,
		...rest
	} = props;
	const { TextArea } = Input;

	const countingUI = useCallback(
		(maxLength: any) => {
			return (
				showCounting && (
					<CountingStyled isRed={value?.length >= maxLength}>
						{`${
							value ? CharacterCounter(value) : 0
						}/${maxLength?.toLocaleString()}`}
					</CountingStyled>
				)
			);
		},
		[value, showCounting]
	);

	return (
		<Wrapper>
			{label && (
				<div className={'label'}>
					{t(label)}
					{required && <span>{'*'}</span>}
				</div>
			)}
			<TextFieldWrapperStyled className={className} width={props.width}>
				<div className={'input-and-counter'}>
					<InputStyled
						as={type === 'textarea' ? TextArea : Input}
						ref={ref}
						prefix={prefix}
						value={value}
						maxLength={maxLength}
						type={type}
						rows={rows}
						status={error && 'error'}
						{...rest}
					/>
					<div className={'field-bottom-wrapper'}>
						<ErrorCounterWrapper>
							{props.error?.length && <ErrorStyled>{props.error}</ErrorStyled>}
						</ErrorCounterWrapper>
						{countingUI(maxLength)}
					</div>
				</div>
			</TextFieldWrapperStyled>
		</Wrapper>
	);
});

export { TextField };
