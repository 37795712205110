import { styled } from 'styled-components';
import { theme } from '../../../theme';

export const Container = styled.div`
	padding: 20px;
	margin-bottom: 25px;
	background: #ffffff;
	border: 1px solid #dedbd5;
	box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.02);
	border-radius: 5px;

	.form-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.left-section {
		/* margin-right: 132px; */
	}

	.btn-wrapper {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}
`;

export const InputContainer = styled.div`
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Label = styled.div`
	color: ${theme.label};
	margin-right: 36px;
	font-weight: 500;
	margin-bottom: 5px;
	font-size: 14px;
	width: 50px;
	white-space: break-spaces;
	word-break: keep-all;
`;

export const Label2 = styled(Label)`
	width: 90px;
`;

export const TableContainer = styled.div`
	width: 100%;
	padding: 20px 20px 35px 20px;
	background: #fff;
	border: 1px solid #dedbd5;
	box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.02);
	border-radius: 5px;
	a {
		color: ${theme?.primaryColor};
	}

	.action-svg {
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			fill: #000000;
			width: 13px;
			height: 14px;
		}
	}

	.icon-style {
		display: flex;
		justify-content: center;
	}

	.remark-text-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
	}

	.remark-text {
		width: 150px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-align: center;
		color: ${theme.black};
		cursor: pointer;
	}

	.colored-text {
		color: ${theme.lightBlue};
		cursor: pointer;
	}
`;
