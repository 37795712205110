import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { firebaseConfig } from './config/firebase';

let firebaseApp = null as any;
if (!getApps.length) {
	firebaseApp = initializeApp(firebaseConfig);
}

// Initialize Firebase
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

export { auth, db, storage, firebaseApp };
