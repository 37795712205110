import { CloseCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { PopupHeader, StyledModal } from './styles';
import { Props } from './types';

const Popup = ({
	popupOpen,
	children,
	okText,
	width,
	onOk,
	onClose,
	padding,
	showOKbutton,
	title,
}: Props) => {
	return (
		<StyledModal
			width={width}
			centered
			padding={padding}
			className={'do-over-ride-popup-antd-style'}
			open={popupOpen}
			closable={false}
			onCancel={onClose}
			maskClosable={false}
			destroyOnClose={true}
			okText={okText}
			onOk={onOk}
			footer={null}
			okButtonProps={
				showOKbutton
					? { style: { display: 'content' } }
					: { style: { display: 'none' } }
			}
		>
			<PopupHeader>
				<div className={'header-main'}>
					<span className={'title'}>{title}</span>
					<CloseCircleOutlined onClick={onClose} />
				</div>
				<Divider className={'divider'} />
			</PopupHeader>
			{children}
		</StyledModal>
	);
};

export { Popup };
