import { PageHeader } from '../../components/molecules';
import { UserList } from '../../components/organisms/UserList/indexs';
import { MainPageWrapper } from '../../utils/global';

const Users = () => {
	return (
		<>
			<PageHeader title={'Users'} />
			<MainPageWrapper>
				<UserList />
			</MainPageWrapper>
		</>
	);
};
export default Users;
