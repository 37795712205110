/* eslint-disable import/no-named-as-default-member */
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationJA from './locales/ja/translation.json';

const resources = {
	en: {
		translation: translationEN,
	},
	ja: {
		translation: translationJA,
	},
};
const supportedLanguages = Object.keys(resources);
const currentLanguage =
	(typeof window !== 'undefined' &&
		window.localStorage.getItem('i18nextLng')) ||
	'';
i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		supportedLngs: supportedLanguages,
		load: 'languageOnly',
		interpolation: {
			escapeValue: false,
		},
		keySeparator: false,
		nsSeparator: '|',
	});
supportedLanguages?.indexOf(currentLanguage) < 0 &&
	i18next.changeLanguage('ja');

export { i18next };
