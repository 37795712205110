import { styled } from 'styled-components';

export const Container = styled.div`
	width: 100%;

	.text-field {
		width: 100%;
	}

	.btn-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: 5px;
	}
`;
