import { PlusCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React from 'react';
import { theme } from '../../../theme';
import { ButtonComponent } from '../../atoms';
import { ButtonContentContainer, Wrapper } from './styles';

interface IProps {
	title?: string | undefined | any;
	showButton?: boolean;
	handleClick?: () => void;
}

export const PageHeader: React.FC<IProps> = ({
	title,
	showButton,
	handleClick,
}) => {
	return (
		<Wrapper>
			<h2 className={'page-header-title'}>{t(title)}</h2>
			{showButton && (
				<ButtonComponent
					type={'medium'}
					width={'90px'}
					background={'none'}
					onClick={handleClick}
					color={theme.black}
					border={`1px solid ${theme.buttonColor}`}
					height={'44px'}
					borderradius={'10px'}
				>
					<ButtonContentContainer>
						<PlusCircleOutlined />
						<span>{'追加'}</span>
					</ButtonContentContainer>
				</ButtonComponent>
			)}
		</Wrapper>
	);
};
