/* eslint-disable no-console */
import { API } from '../api';

interface INews {
	date: string;
	content: string;
}

interface INewsParams {
	page?: number;
	pageSize?: number;
}

export const getAllNews = async ({ queryKey }: any): Promise<any> => {
	const params = {} as INewsParams;
	params.page = parseInt(queryKey[1]);
	params.pageSize = parseInt(queryKey[2]);
	return API.get(`/news`, { params });
};

export const getOneNews = async ({ queryKey }: any): Promise<any> => {
	const id = queryKey[1];
	return API.get(`/news/${id}`);
};

export const addNews = async (data: INews): Promise<any> => {
	return API.post(`/news/create-news/`, {
		date: data?.date,
		content: data?.content,
	});
};

export const updateNews = async (data: any): Promise<any> => {
	return API.put(`/news/update-news/${data?.id}`, {
		date: data?.date,
		content: data?.content,
	});
};

export const deleteNews = async (id: string): Promise<any> => {
	return API.delete(`/news/delete-news/${id}`);
};
