export interface ICommentProps {
	title: string;
	commentData?: IComment[];
	formik: any;
	commentType: string;
}

export interface IComment {
	user: string;
	comment: string;
	created_at: any;
}

export const commentTypes = ['key-visual', 'story', 'risk', 'slide-show'];

export const sortCommentByDate = (comment: Array<IComment>) => {
	if (comment.length >= 2) {
		const sortedComments = [...comment].sort(
			(a, b) =>
				new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
		);
		return sortedComments;
	}
	return comment;
};

export const currentUserType = 'creator';

export const onChangedComment = (
	e: any,
	formik: any,
	fieldKey: string,
	existingComments: any
) => {
	const newComment: IComment = {
		user: currentUserType,
		comment: e.target.value,
		created_at: new Date(),
	};
	let updatedComments = [...existingComments, newComment];
	if (e.target.value === '') {
		updatedComments = existingComments;
	}
	formik.setFieldValue(fieldKey, updatedComments);
};
