/* eslint-disable no-console */
import { theme } from '../../../theme';
import { ButtonComponent, TextField } from '../../atoms';
import { Container } from './styles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { notification } from 'antd';
import { t } from 'i18next';
import { updateRemarks } from '../../../services/orders';

export const RemarkModal = ({
	detail,
	closeModal,
	refetch,
}: {
	detail: { id: string; remarks: string };
	closeModal: () => void;
	refetch: () => void;
}) => {
	const { mutate, isLoading } = useMutation(updateRemarks, {
		onSuccess: () => {
			notification.success({
				message: t('Order updated successfully'),
			});
			refetch();
			closeModal();
			formik.resetForm({});
		},
		onError: () => {
			notification.error({
				message: t('An error has occurred. Please try again later'),
			});
			refetch();
			closeModal();
		},
	});
	const validationSchema = yup.object().shape({
		remarks: yup.string().required('入力ください'),
	});

	const formik = useFormik({
		initialValues: {
			remarks: detail?.remarks,
			id: detail?.id,
		},
		validationSchema,
		onSubmit: (value: any) => {
			mutate(value);
		},
	});

	return (
		<Container>
			<form onSubmit={formik.handleSubmit}>
				<TextField
					name={'remarks'}
					error={formik.touched.remarks && formik.errors.remarks}
					onChange={formik.handleChange}
					value={formik.values.remarks}
					onBlur={formik.handleBlur}
					fullWidth={true}
					height={'52px'}
					className={'text-field'}
					showCounting
					maxLength={30}
				/>
				<div className={'btn-wrapper'}>
					<ButtonComponent
						htmlType={'submit'}
						type={'small'}
						width={'89px'}
						background={theme?.buttonColor}
						color={theme.white}
						loading={isLoading}
					>
						{'保存 '}
					</ButtonComponent>
				</div>
			</form>
		</Container>
	);
};
