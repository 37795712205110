import { styled } from 'styled-components';
import { theme } from '../../../theme';

export const Container = styled.div`
	width: 100%;

	.text-field {
		width: 100%;
	}

	.btn-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: 5px;
		button {
			letter-spacing: -2px;
		}
	}
`;

export const DatePickerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	& .ant-picker {
		border-radius: 5px;
		height: 42px;
		width: 149px;
	}
	& .ant-picker:focus,
	:active,
	:focus-within {
		border-color: ${theme?.primary_600};
	}

	& .ant-picker:hover {
		border-color: ${theme?.neutral_700};
	}
`;
export const ErrorText = styled.div`
	font-size: 12px;
	color: ${theme.errorColor};
	margin-left: 2px;
	padding-top: 4px;
`;
export const Lable = styled.div`
	color: ${theme.label};
	margin-right: 10px;
	font-weight: 500;
	margin-bottom: 5px;
	font-size: 16px;
	${theme.typography.typographySmall}
`;
