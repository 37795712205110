import styled from 'styled-components';
import { theme } from '../../../theme';

export const Container = styled.div`
	width: 100%;
	padding: 28px 0px 0px 0px;
`;
export const Title = styled.div`
	font-size: 18px;
	font-weight: 700;
	color: ${theme.black};
`;

export const FormContainer = styled.div`
	background-color: #fff;
	padding: 28px;
	display: flex;
	flex-direction: column;
	/* flex-wrap: wrap; */
	justify-content: space-between;
	gap: 20px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #dedbd5;
	background: #fff;
	box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.02);
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export const FieldWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 100%;
	flex-direction: row;
	justify-content: space-between;
`;

export const RowFieldWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	.field-full-width {
		/* max-width: 500px; */
		/* width: 100%; */
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.padding-input {
		/* margin-left: 50px; */
		display: flex;
		flex-direction: row;
		/* gap: 50px; */
		/* justify-content: space-between; */
		width: 100%;
	}
`;

export const FormWrapper = styled.div`
	/* width: 100%; */

	.save-btn-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 28px;

		:active,
		:focus {
			border-color: ${theme?.primary_90};
		}
	}

	.form-contents {
		background: #fff;
		padding-bottom: 20px;
	}
`;

export const ReturnHeader = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-bottom: 10px;

	p {
		margin-bottom: 0px;
		font-size: 16px;
		margin-right: 20px;
	}

	svg {
		cursor: pointer;
		fill: ${theme?.errorColor};
		width: 20px;
		height: 20px;
	}
`;

export const Label = styled.div`
	color: ${theme.label};
	font-weight: 500;
	margin-bottom: 5px;
	font-size: 14px;
	min-width: 100px;
	display: flex;
	align-items: center;
	word-break: keep-all;
`;
