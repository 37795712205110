import { LoadingOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { SelectDataProps } from './types';
import { ErrorStyled, Label, SelectContainer, Wrapper } from './styles';

const SelectComponent = (props: SelectDataProps) => {
	const {
		label,
		value,
		width,
		loading,
		max_width,
		className,
		errorMessage,
		required,
		defaultValue,
		id,
		inlineError,
		options,
		disabledValues,
		...rest
	} = props;

	return (
		<Wrapper inlineError={inlineError}>
			<SelectContainer
				$width={width}
				$maxWidth={max_width}
				className={className}
				$error={Boolean(errorMessage)}
			>
				{label && (
					<>
						<Label>
							<div className={'label'}>
								{label}
								{required && <span>{'*'}</span>}
							</div>
						</Label>
					</>
				)}
				<Select
					id={id}
					value={value || undefined}
					defaultValue={defaultValue}
					getPopupContainer={(trigger) => trigger.parentNode}
					suffixIcon={
						loading ? (
							<LoadingOutlined />
						) : (
							<CaretDownOutlined className={'caret-down-icon'} />
						)
					}
					status={errorMessage && 'error'}
					{...rest}
				>
					{options?.map((option) => (
						<Select.Option
							key={option.value}
							value={option.value}
							disabled={disabledValues?.includes(option.value)}
						>
							{option.label}
						</Select.Option>
					))}
				</Select>
			</SelectContainer>
			{errorMessage ? (
				<ErrorStyled className={'errorLabel'}>{errorMessage}</ErrorStyled>
			) : null}
		</Wrapper>
	);
};

export { SelectComponent };
