import { useContext, useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { message } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { theme } from '../../theme';
import { ButtonComponent, TextField } from '../../components/atoms';
import { ILogin } from './type';
import { useFormik } from 'formik';
import * as yup from 'yup';

const Wrapper = styled.div`
	height: calc(100vh - 20px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${theme.neutral_100};
`;

const LogoWrapper = styled.div`
	width: 260px;
	height: 120px;
	background: ${theme.primaryColor};
`;

const LoginForm = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
	width: 685px;
	border: 1px solid ${theme.neutral_400};
	background-color: ${theme.neutral_100};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.header {
		color: ${theme.primaryColor};
		font-size: 22px;
		font-weight: 500;
		margin-top: 75px;
		margin-bottom: 10px;
	}
	.label {
		color: ${theme.primaryColor};
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 10px;
		span {
			color: red;
			margin-left: 3px;
		}
	}
	button {
		margin-bottom: 40px;
	}
`;

const TextFieldWrapper = styled.div`
	margin-bottom: 30px;
`;

function Login() {
	const { setUser } = useContext(AuthContext);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handleLogin = async (e: any) => {
		setIsLoading(true);
		e.preventDefault;
		try {
			const data = await signInWithEmailAndPassword(
				auth,
				formik.values.email,
				formik.values.password
			);
			if (!data || !data.user || !auth.currentUser) {
				return;
			}
			setUser(data.user);
			navigate('/');
			setIsLoading(false);
		} catch (error: any) {
			const errorCode = error.code;
			if (errorCode === 'auth/user-not-found') {
				message.error({
					content: t('Your email address or password is incorrect'),
					key: '1',
					icon: <CloseCircleFilled onClick={() => message.destroy('1')} />,
				});
			} else if (errorCode === 'auth/wrong-password') {
				message.error({
					content: t('Your email address or password is incorrect'),
					key: '2',
					icon: <CloseCircleFilled onClick={() => message.destroy('2')} />,
				});
			} else if (errorCode === 'auth/user-disabled') {
				message.error({
					content: t(
						'We could not login you at this moment. Please contact your administration for inquiry'
					),
					key: '3',
					icon: <CloseCircleFilled onClick={() => message.destroy('3')} />,
				});
			} else {
				message.error({
					key: '4',
					icon: <CloseCircleFilled onClick={() => message.destroy('4')} />,
					content: t('An error has occurred. Please try again later'),
				});
			}
			setIsLoading(false);
		}
	};
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email('メールアドレスを正しくご記入ください')
			.required('メールアドレスを入力ください'),
		password: yup.string().required('パスワードを入力ください'),
	});
	const formik = useFormik<ILogin>({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: handleLogin,
	});

	return (
		<Wrapper>
			<LogoWrapper>
				<img
					src={'/assets/icons/logo.svg'}
					alt={'lp-designer-logo'}
					width={260}
					height={120}
				/>
			</LogoWrapper>
			<LoginForm>
				<div className={'header'}>{t('Login')}</div>
				<form onSubmit={formik.handleSubmit}>
					<TextFieldWrapper>
						<div className={'label'}>
							{t('Email')}
							<span>{'*'}</span>
						</div>
						<TextField
							name={'email'}
							error={formik.touched.email && formik.errors.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							width={'600px'}
							className={'login-text-field'}
							required
						></TextField>
					</TextFieldWrapper>
					<TextFieldWrapper>
						<div className={'label'}>
							{t('Password')}
							<span>{'*'}</span>
						</div>
						<TextField
							name={'password'}
							type={'password'}
							error={formik.touched.password && formik.errors.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							width={'600px'}
							required
							className={'login-text-field'}
						></TextField>
					</TextFieldWrapper>
					<ButtonComponent
						type={'medium'}
						width={'190px'}
						height={'55px'}
						borderradius={'10px'}
						loading={isLoading}
						htmlType={'submit'}
					>
						{t('Login')}
					</ButtonComponent>
				</form>
			</LoginForm>
		</Wrapper>
	);
}

export default Login;
