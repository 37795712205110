/* eslint-disable no-console */
import { t } from 'i18next';
import { CommentComponent } from '../CommentComponent';
import { RiskChallenges } from '../RiskChallenges';
import { IProjectProps, STATUS_OPTIONS, disabledValues } from './helper';
import {
	ButtonWrapper,
	Container,
	CustomButton,
	FieldWrapper,
	Spacer,
	StatusContainer,
	Wrapper,
} from './style';
import { ImageContainer } from '../../molecules';
import { SelectComponent } from '../../atoms/Select';
import { theme } from '../../../theme';
import { Story } from '../Story';
import { Error, TextField } from '../../atoms';

export const ProjectView: React.FC<IProjectProps> = ({
	formik,
	isLoading,
	projectData,
}) => {
	return (
		<Wrapper>
			<form onSubmit={formik.handleSubmit} className={'project-form'}>
				<StatusContainer>
					<SelectComponent
						className={'statusLable'}
						id={`status`}
						label={`${t('Status')} :`}
						width={'234px'}
						max_width={'234px'}
						options={STATUS_OPTIONS}
						inlineError={true}
						disabledValues={disabledValues(projectData?.status)}
						style={{
							marginRight: '50px',
							height: '42px',
						}}
						value={formik.values.status}
						onChange={(value: any) => {
							formik.setFieldValue('status', value);
						}}
						errorMessage={formik.touched.status && formik.errors.status}
						onBlur={formik.handleBlur}
					/>
					<Error>
						{t(
							'*Change the status to complete and save to send the invoice to the orderer'
						)}
					</Error>
				</StatusContainer>

				<Container>
					<ImageContainer
						title={t('Key Visual')}
						showUploadButton={true}
						imageList={formik.values.key_visual?.images}
						defaultRatio={false}
						alertText={'You can upload up to 3 images'}
						disableUpload={formik.values.key_visual?.images.length === 3}
						handleChange={(image: string) => {
							if (formik.values.key_visual?.images.length < 3) {
								formik.setFieldValue('key_visual.images', [
									...formik.values.key_visual?.images,
									image,
								]);
							}
						}}
						errorMessage={
							formik.touched.key_visual?.images &&
							formik.errors.key_visual?.images
						}
					/>

					<FieldWrapper>
						<p className={'title'}>{`${t('Project title')} :`}</p>
						<div className={'text-field-container'}>
							<TextField
								fullWidth={true}
								width={'100%'}
								height={'100px'}
								name={'project_title'}
								onChange={(e) => {
									formik.setFieldValue(
										'key_visual.project_title',
										e.target.value
									);
								}}
								onBlur={formik.handleBlur}
								value={formik.values?.key_visual?.project_title}
								showCounting={true}
								maxLength={47}
							/>
						</div>
					</FieldWrapper>

					<CommentComponent
						commentType={'key-visual'}
						title={t('Key Visual Comments')}
						commentData={formik.initialValues.key_visual?.comments}
						formik={formik}
					/>
				</Container>
				<Container>
					<Story formik={formik} />
				</Container>
				<Container>
					<RiskChallenges formik={formik} />
					<CommentComponent
						commentType={'risk'}
						title={t('Risk & challenges Comments')}
						commentData={formik.initialValues.risk_and_challenges?.comments}
						formik={formik}
					/>
				</Container>
				<Container>
					<ImageContainer
						title={t('Image slideshow')}
						imageList={formik.values.image_slide_show?.images}
						alertText={'You can upload up to 7 images'}
						defaultRatio={false}
						carouselImages={false}
						disableUpload={formik.values.image_slide_show?.images.length === 7}
						handleChange={(image: string) => {
							if (formik.values.image_slide_show?.images.length < 7) {
								formik.setFieldValue('image_slide_show.images', [
									...formik.values.image_slide_show?.images,
									image,
								]);
							}
						}}
						errorMessage={
							formik.touched.image_slide_show?.images &&
							formik.errors.image_slide_show?.images
						}
					/>
					<CommentComponent
						commentType={'slide-show'}
						title={t('Image Slideshow Comments')}
						commentData={formik.initialValues.image_slide_show?.comments}
						formik={formik}
					/>
				</Container>
				<Spacer />
				<ButtonWrapper>
					<CustomButton
						type={'large'}
						borderradius={'62px'}
						width={'200px'}
						fontSize={'16px'}
						bold={true}
						backgroundColorWhenDisabled={'transparent'}
						background={theme?.red}
						disabled={!formik.dirty}
						htmlType={'submit'}
						loading={isLoading}
					>
						{t('Save and go to next tab')}
					</CustomButton>
				</ButtonWrapper>
			</form>
		</Wrapper>
	);
};
