import { t } from 'i18next';
import { CommentWrapper, DateLabel, Wrapper } from './styles';
import { CommentMessageProps } from './type';

const CommentMessage: React.FC<CommentMessageProps> = ({
	label,
	bgcolor,
	bordercolor,
	dateTime,
	message,
}) => {
	return (
		<Wrapper>
			<CommentWrapper bgColor={bgcolor} borderColor={bordercolor}>
				{label && (
					<div className={'label'}>
						{t(label)}
						{':'}
					</div>
				)}
				{message && <div className={'messageContainer'}>{t(message)}</div>}
			</CommentWrapper>
			{dateTime && <DateLabel>{dateTime}</DateLabel>}
		</Wrapper>
	);
};

export { CommentMessage };
