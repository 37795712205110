import styled from 'styled-components';
import { theme } from '../../../theme';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	.label {
		color: ${theme.label};
		margin-right: 36px;
		font-weight: 400;
		margin-bottom: 5px;
		max-width: 140px;
		width: 100%;
		font-size: 16px;
		font-size: 16px;
		${theme.typography.typographySmall}
		span {
			color: red;
		}
	}
	.ant-picker {
		width: 100%;
		border-radius: 5px;
		border: 1px solid ${theme.borderBGColor};
		height: 42px;
	}
`;
