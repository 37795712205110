import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRouteAdmin';
import RestrictedRoute from './RestrictedRoute';
import NotFound from './pages/NotFound';
import Login from './pages/login';
import News from './pages/news';
import './utils/global.css';

import Orders from './pages/orders';
import Users from './pages/users';
import StatusManagement from './pages/status-management';
function App() {
	return (
		<Routes>
			<Route
				path={'/'}
				element={
					<RestrictedRoute>
						<Login />
					</RestrictedRoute>
				}
			/>
			<Route
				path={'/orders'}
				element={
					<PrivateRoute>
						<Orders />
					</PrivateRoute>
				}
			/>
			<Route
				path={'/news'}
				element={
					<PrivateRoute>
						<News />
					</PrivateRoute>
				}
			/>
			<Route
				path={'/users'}
				element={
					<PrivateRoute>
						<Users />
					</PrivateRoute>
				}
			/>
			<Route
				path={'/status-management/:orderID'}
				element={
					<PrivateRoute>
						<StatusManagement />
					</PrivateRoute>
				}
			/>
			<Route path={'*'} element={<NotFound />} />
		</Routes>
	);
}

export default App;
