import { FieldArrayRenderProps } from 'formik';

export interface IUrl {
	url: string;
}
export interface IShootingData {
	formik: any;
	onClick: () => void;
	isLoading: boolean;
	savedData: any;
}

export interface IShooting {
	shootings: Array<IUrl>;
}

export const handleCreateNewForm = (
	arrayHelpers: FieldArrayRenderProps,
	index: number
) => {
	arrayHelpers.insert(index, {
		url: '',
	});
};

export const isLastIndex = (index: number, formik: any) => {
	if (index == formik.values?.shootingDataUrls?.length - 1) {
		return true;
	}
	return false;
};

export const handleFieldLimit = (formik: any) => {
	if (formik.values?.shootingDataUrls?.length == 5) {
		return true;
	}
	return false;
};
