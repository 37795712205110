import { styled } from 'styled-components';
import { theme } from '../../theme';

export const Wrapper = styled.div`
	width: 100%;
	min-height: 582px;
	padding: 28px 38px;
	background: ${theme?.white};

	h3 {
		font-size: 18px;
	}
`;

export const FormWrapper = styled.div`
	padding-top: 40px;
	padding-bottom: 40px;
	width: 100%;

	.save-btn-wrapper {
		display: flex;
		justify-content: center;
	}

	.form-divider {
		margin: 0px;
		padding: 8px 28px;
	}
`;

export const FieldWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;

	.field-and-button-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.action-button-wrapper {
		margin: 0 0 0 20px;
	}

	.delete-icon {
		svg {
			fill: ${theme?.errorColor};
			width: 36px;
			height: 20px;
		}

		cursor: pointer;
	}
`;

export const ErrorCounterWrapper = styled.div`
	display: flex;
	margin-top: 4px;
`;

export const ErrorStyled = styled.span`
	font-size: 12px;
	color: ${theme.errorColor};
	margin-left: 2px;
`;

export const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;
