import { styled } from 'styled-components';
import { theme } from '../../../theme';

export const Container = styled.div`
	padding: 20px;
	margin-bottom: 25px;
	background: #ffffff;
	border: 1px solid #dedbd5;
	box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.02);
	border-radius: 5px;

	.form-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.left-section {
		/* margin-right: 132px; */
	}

	.btn-wrapper {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}
`;

export const InputContainer = styled.div`
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const TableContainer = styled.div`
	width: 100%;
	padding: 20px 20px 35px 20px;
	background: #fff;
	border: 1px solid #dedbd5;
	box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.02);
	border-radius: 5px;
	overflow-x: scroll;
	a {
		color: ${theme?.primaryColor};
	}

	.actions {
		display: flex;
		align-items: center;
		flex-direction: row;
		/* justify-content: flex-end; */
	}
`;
