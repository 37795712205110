/* eslint-disable no-console */
import { DeleteOutlined } from '@ant-design/icons';
import { FieldArray, FieldArrayRenderProps, FormikProvider } from 'formik';
import { t } from 'i18next';
import { theme } from '../../../theme';
import { SCHEDULE_OPTIONS } from '../../../utils/constants';
import { getDynamicFieldErrorMessage } from '../../../utils/getDynamicFieldErrorMessage';
import { ButtonComponent, TextField } from '../../atoms';
import { SelectComponent } from '../../atoms/Select';
import { ReturnData } from '../ProjectView/helper';
import {
	RiskChallengesProps,
	handleCreateNewForm,
	handleFieldLimit,
	isLastIndex,
} from './helpers';
import {
	Container,
	FieldWrapper,
	FormContainer,
	FormWrapper,
	InputWrapper,
	Label,
	ReturnHeader,
	RowFieldWrapper,
	Title,
} from './styles';
import { getNumberWithCommas } from '../../../utils';

export const RiskChallenges: React.FC<RiskChallengesProps> = ({ formik }) => {
	const handleDeleteReturnData = (
		arrayHelpers: FieldArrayRenderProps,
		i: number
	) => {
		arrayHelpers.remove(i);
	};
	return (
		<FormWrapper>
			<Title>{t('Risk & challenges')}</Title>
			<form onSubmit={formik?.handleSubmit}>
				<FormikProvider value={formik}>
					<FieldArray
						name={'risk_and_challenges.returnsData'}
						validateOnChange={true}
						render={(arrayHelpers) => {
							return (
								<div className={'form-contents'}>
									{Boolean(
										arrayHelpers.form.values?.risk_and_challenges
											?.returnsData &&
											arrayHelpers.form.values?.risk_and_challenges?.returnsData
												.length > 0 &&
											Array.isArray(
												arrayHelpers.form.values?.risk_and_challenges
													?.returnsData
											)
									) &&
										arrayHelpers.form.values?.risk_and_challenges?.returnsData.map(
											(value: ReturnData, i: number) => {
												return (
													<>
														<Container key={i}>
															<ReturnHeader>
																<p>
																	{t('Return')}
																	{`${i + 1}`}
																</p>
																{i !== 0 && (
																	<DeleteOutlined
																		onClick={() =>
																			handleDeleteReturnData(arrayHelpers, i)
																		}
																	/>
																)}
															</ReturnHeader>
															<FormContainer>
																<RowFieldWrapper>
																	<InputWrapper>
																		<Label>{'タイトル'}</Label>
																		<TextField
																			name={`risk_and_challenges.returnsData.${i}.title`}
																			width={'100%'}
																			fullWidth={true}
																			height={'42px'}
																			className={'field-full-width'}
																			showCounting
																			maxLength={50}
																			value={value?.title}
																			error={getDynamicFieldErrorMessage(
																				'risk_and_challenges',
																				'title',
																				i,
																				formik,
																				'returnsData'
																			)}
																			onChange={formik.handleChange}
																			onBlur={formik.handleBlur}
																		/>
																	</InputWrapper>

																	<InputWrapper>
																		<div className={'padding-input'}>
																			<Label style={{ paddingLeft: '50px' }}>
																				{'金額'}
																			</Label>
																			<TextField
																				fullWidth={true}
																				name={`risk_and_challenges.returnsData.${i}.amount`}
																				width={'100%'}
																				height={'42px'}
																				className={'field-full-width'}
																				value={value?.amount}
																				error={getDynamicFieldErrorMessage(
																					'risk_and_challenges',
																					'amount',
																					i,
																					formik,

																					'returnsData'
																				)}
																				maxLength={8}
																				onChange={(e) => {
																					formik.setFieldValue(
																						`risk_and_challenges.returnsData.${i}.amount`,
																						getNumberWithCommas(e.target.value)
																					);
																				}}
																				onBlur={formik.handleBlur}
																			/>
																		</div>
																	</InputWrapper>
																</RowFieldWrapper>

																<InputWrapper>
																	<Label>{'詳細説明'}</Label>
																	<TextField
																		name={`risk_and_challenges.returnsData.${i}.description`}
																		width={'100%'}
																		height={'42px'}
																		showCounting
																		maxLength={100}
																		value={value.description}
																		error={getDynamicFieldErrorMessage(
																			'risk_and_challenges',
																			'description',
																			i,
																			formik,

																			'returnsData'
																		)}
																		onChange={formik.handleChange}
																		onBlur={formik.handleBlur}
																	/>
																</InputWrapper>

																<FieldWrapper>
																	<Label>{'お届け予定月'}</Label>
																	<SelectComponent
																		id={`risk_and_challenges.returnsData.${i}.schedule_delivery`}
																		width={'131px'}
																		max_width={'131px'}
																		options={SCHEDULE_OPTIONS()}
																		style={{
																			marginRight: '50px',
																			height: '42px',
																		}}
																		value={value.schedule_delivery}
																		errorMessage={getDynamicFieldErrorMessage(
																			'risk_and_challenges',
																			'schedule_delivery',
																			i,
																			formik,
																			'returnsData'
																		)}
																		onChange={(value: any) =>
																			formik.setFieldValue(
																				`risk_and_challenges.returnsData.${i}.schedule_delivery`,
																				value
																			)
																		}
																		onBlur={formik.handleBlur}
																	/>
																	<InputWrapper>
																		<Label style={{ paddingLeft: '50px' }}>
																			{'個数'}
																		</Label>
																		<TextField
																			name={`risk_and_challenges.returnsData.${i}.units`}
																			fullWidth={true}
																			width={'100%'}
																			height={'42px'}
																			value={value.units}
																			error={getDynamicFieldErrorMessage(
																				'risk_and_challenges',
																				'units',
																				i,
																				formik,

																				'returnsData'
																			)}
																			onChange={(e) => {
																				const units = e.target.value;
																				if (
																					/^[0-9]*\.?[0-9]*$/.test(units) &&
																					units?.length <= 4
																				) {
																					formik.setFieldValue(
																						`risk_and_challenges.returnsData.${i}.units`,
																						units
																					);
																				}
																			}}
																			onBlur={formik.handleBlur}
																		/>
																	</InputWrapper>
																</FieldWrapper>

																<FieldWrapper>
																	<InputWrapper>
																		<Label>{'備考'}</Label>
																		<TextField
																			name={`risk_and_challenges.returnsData.${i}.remarks`}
																			width={'100%'}
																			height={'42px'}
																			showCounting
																			maxLength={50}
																			value={value.remarks}
																			error={getDynamicFieldErrorMessage(
																				'risk_and_challenges',
																				'remarks',
																				i,
																				formik,

																				'returnsData'
																			)}
																			onChange={formik.handleChange}
																			onBlur={formik.handleBlur}
																		/>
																	</InputWrapper>
																</FieldWrapper>
															</FormContainer>
														</Container>
														{isLastIndex(i, formik) && (
															<div className={'save-btn-wrapper'}>
																<ButtonComponent
																	type={'medium'}
																	borderradius={'62px'}
																	width={'176px'}
																	fontSize={'16px'}
																	color={theme?.primary_90}
																	bold={true}
																	background={theme?.white}
																	htmlType={'submit'}
																	bordercolor={theme?.primary_90}
																	disabled={handleFieldLimit(formik)}
																	onClick={() => {
																		handleCreateNewForm(arrayHelpers, i + 1);
																	}}
																>
																	{`+   ${t('Add more')}`}
																</ButtonComponent>
															</div>
														)}
													</>
												);
											}
										)}
								</div>
							);
						}}
					/>
				</FormikProvider>
			</form>
		</FormWrapper>
	);
};
