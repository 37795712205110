import { styled } from 'styled-components';
import { TextFieldProps } from './types';
import { theme } from '../../../theme';
import { Input } from 'antd';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	.label {
		color: ${theme.label};
		margin-right: 36px;
		font-weight: 500;
		margin-bottom: 5px;
		font-size: 16px;
		min-width: 50px;
		white-space: break-spaces;
		word-break: keep-all;
		${theme.typography.typographySmall}
		span {
			color: red;
		}
	}
	input::placeholder {
		color: ${theme.greyPlaceHolder};
	}
	.ant-input-data-count {
		font-size: 12px;
		color: ${theme.countColor};
	}
`;

export const InputStyled = styled(Input)<TextFieldProps>`
	resize: auto;
	border-color: ${({ bordercolor }: TextFieldProps) =>
		bordercolor || theme.neutral_400};

	width: ${({ width, fullWidth }: TextFieldProps) =>
		fullWidth ? '100%' : width || 'auto'};
	position: relative;
`;

export const ErrorStyled = styled.span`
	font-size: 12px;
	color: ${theme.errorColor};
	margin-left: 2px;
`;

export const CountingStyled = styled.div<{ isRed: boolean }>`
	font-size: 12px;
	color: ${({ isRed }) => (isRed ? theme.errorColor : theme.countColor)};
`;

export const ErrorCounterWrapper = styled.div`
	display: flex;
`;

export const TextFieldWrapperStyled = styled.div<TextFieldProps>`
	/* width: 100%; */

	.ant-input {
		padding: 9px 0 9px 9px;
	}
	.ant-input:hover {
		border-color: ${({ bordercolor }: TextFieldProps) =>
			bordercolor || theme.neutral_400};
	}

	.ant-input:focus {
		border-color: ${({ bordercolor }: TextFieldProps) =>
			bordercolor || theme.primary_600};
	}

	::placeholder {
		color: ${theme?.greyPlaceHolder};
		${theme.typography.typographySmall}
		font-weight: normal;
		vertical-align: middle;
	}

	width: ${({ width, fullWidth }: TextFieldProps) =>
		fullWidth ? '100%' : width || 'auto'};

	.field-bottom-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-top: 6px;
	}

	.input-and-counter {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
`;
