/* eslint-disable no-console */
import { notification } from 'antd';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { updateStatus } from '../../../services/status';
import { ScheduleView, StatusDesign, StatusShooting } from '../../organisms';
import { ProjectView } from '../../organisms/ProjectView';
import { Project } from '../../organisms/ProjectView/helper';
import { Schedule } from '../../organisms/ScheduleView/helper';
import { StatusFeedback } from '../../organisms/StatusFeedback';
import {
	IStatusProps,
	designUrls,
	feedbackUrls,
	handleChangeTabAfterSubmit,
	projectInitialValues,
	projectValidationSchema,
	scheduleInitialValues,
	shootingUrls,
	tabMenus,
	urlDesignValidation,
	urlFeedValidation,
	urlshootingValidation,
} from './helper';
import {
	ErrorTitle,
	MainContainer,
	NavLinks,
	TabContainer,
	Wrapper,
} from './styles';

const TabBar: React.FC<IStatusProps> = ({ statusData, refetchStatus }) => {
	const [activeKey, setActiveKey] = useState<string>(tabMenus[0].key);
	const { orderID } = useParams();

	const handleSelectTab = (name: string) => {
		if (name !== activeKey) {
			setActiveKey(name);
		}
	};

	const { mutate, isLoading } = useMutation(updateStatus, {
		onSuccess: () => {
			notification.success({
				message: t('Status updated successfully'),
			});
			refetchStatus();
			handleChangeTabAfterSubmit(activeKey, setActiveKey);
		},
		onError: () => {
			notification.error({
				message: t('An error has occurred. Please try again later'),
			});
		},
	});

	//Formik instance for project form
	const projectformik = useFormik<Project>({
		initialValues: statusData?.project || projectInitialValues,
		validationSchema: projectValidationSchema,
		enableReinitialize: true,
		onSubmit: (values: any) => {
			mutate({ project: { ...values }, id: orderID });
		},
	});

	//formik instance for schedult form
	const scheduleFormik = useFormik<Schedule>({
		initialValues: statusData?.schedule || scheduleInitialValues,
		enableReinitialize: true,
		onSubmit: (values: any) => {
			mutate({ schedule: { ...values }, id: orderID });
		},
	});

	//formik instance for shooting form
	const designFormik = useFormik({
		initialValues: {
			designDataUrls: statusData?.designDataUrls?.designDataUrls || designUrls,
		},
		enableReinitialize: true,
		validationSchema: urlDesignValidation,
		onSubmit: (values: any) => {
			mutate({ designDataUrls: { ...values }, id: orderID });
		},
	});

	//Formik instance for shooting form
	const shootingFormik = useFormik({
		initialValues: {
			shootingDataUrls:
				statusData?.shootingDataUrls?.shootingDataUrls || shootingUrls,
		},
		enableReinitialize: true,
		validationSchema: urlshootingValidation,
		onSubmit: (values: any) => {
			mutate({ shootingDataUrls: { ...values }, id: orderID });
		},
	});

	//Formik instance for feedback form
	const feedbackFormik = useFormik({
		initialValues: {
			feedbackDataUrls:
				statusData?.feedbackDataUrls?.feedbackDataUrls || feedbackUrls,
		},
		enableReinitialize: true,
		validationSchema: urlFeedValidation,
		onSubmit: (values: any) => {
			mutate({ feedbackDataUrls: { ...values }, id: orderID });
		},
	});

	const getTabComponent = (activeKey: string) => {
		switch (activeKey) {
			case tabMenus[0].key:
				return (
					<>
						<ScheduleView
							onClick={() => {
								handleSelectTab(tabMenus[1].key);
								scheduleFormik.handleSubmit;
							}}
							formik={scheduleFormik}
							isLoading={isLoading}
						/>
					</>
				);
			case tabMenus[1].key:
				return (
					<ProjectView
						formik={projectformik}
						isLoading={isLoading}
						projectData={statusData?.project}
						onClick={() => {
							console.log('project section data', projectformik.values);
							//handleSelectTab(tabMenus[2].key);
						}}
					/>
				);
			case tabMenus[2].key:
				return (
					<StatusShooting
						formik={shootingFormik}
						isLoading={isLoading}
						savedData={statusData?.shootingDataUrls?.shootingDataUrls}
						onClick={() => handleSelectTab(tabMenus[3].key)}
					/>
				);
			case tabMenus[3].key:
				return (
					<StatusDesign
						formik={designFormik}
						isLoading={isLoading}
						savedData={statusData?.designDataUrls?.designDataUrls}
						onClick={() => handleSelectTab(tabMenus[4].key)}
					/>
				);
			case tabMenus[4].key:
				return (
					<StatusFeedback
						formik={feedbackFormik}
						isLoading={isLoading}
						savedData={statusData?.feedbackDataUrls?.feedbackDataUrls}
						onClick={() => {
							handleSelectTab(tabMenus[0].key);
						}}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<MainContainer>
			<TabContainer>
				<NavLinks>
					{tabMenus.map((value: { name: string; key: string }) => {
						return (
							<li key={value.key}>
								<button
									className={activeKey == value.key ? 'active' : ''}
									onClick={() => handleSelectTab(value.key)}
								>
									{t(value.name)}
								</button>
							</li>
						);
					})}
				</NavLinks>
			</TabContainer>

			<ErrorTitle>
				{
					'＊入力内容を保存して送信するには画面したの保存ボタンをクリックしてください'
				}
			</ErrorTitle>
			<Wrapper>{getTabComponent(activeKey)}</Wrapper>
		</MainContainer>
	);
};

export { TabBar };
