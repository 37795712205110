import { styled } from 'styled-components';
import { theme } from '../../../theme';

export const TableContainer = styled.div`
	width: 100%;
	padding: 20px 20px 35px 20px;
	background: #fff;
	border: 1px solid #dedbd5;
	box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.02);
	border-radius: 5px;
	a {
		color: ${theme?.primaryColor};
	}
	.ant-table-tbody {
		word-break: break-word !important;
	}

	.actions {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 25px;
		/* justify-content: flex-end; */
	}
	.action-svg {
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			fill: #000000;
			width: 14px;
			height: 15px;
		}
	}
`;
