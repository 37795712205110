import { Layout } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { AuthContext } from './AuthProvider';
import { SideNav } from './components/molecules';

const PrivateRoute = ({ children }: any) => {
	const { authenticated } = useContext(AuthContext);
	const { Sider } = Layout;
	const location = useLocation();
	const [hideNavbar, setHideNavbar] = useState(false);

	//Handle hide/show side navbar based on the route name
	useEffect(() => {
		const pathArray = location.pathname.split('/');
		const statusManagement = pathArray[1];

		if (statusManagement === 'status-management') {
			setHideNavbar(true);
		} else {
			setHideNavbar(false);
		}
	}, [location]);

	const LayoutWrapper = styled.div`
		width: 100%;
		overflow-x: auto;
	`;

	return authenticated ? (
		<Layout>
			{!hideNavbar ? (
				<Sider trigger={null} collapsed={false}>
					<SideNav />
				</Sider>
			) : (
				<></>
			)}
			<LayoutWrapper>{children}</LayoutWrapper>
		</Layout>
	) : (
		<Navigate to={'/'} replace />
	);
};

export default PrivateRoute;
