import styled from 'styled-components';
import { theme } from '../../../theme';
import { ButtonComponent } from '../../atoms';

export const Wrapper = styled.div`
	.project-form {
		background: #fff;
	}
`;

export const Container = styled.div`
	width: 100%;

	padding: 28px 28px 28px 28px;
	background-color: ${theme.white};
	border-bottom: 1px solid ${theme.borderBGColor};
	.statusContainer {
		.statusLable {
			.label {
				font-size: 18px;
				font-weight: 700;
			}
		}
		.errorLabel {
			margin-left: -30px;
			padding: 0;
		}
	}
`;

export const StatusContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;

	padding: 28px 28px 28px 28px;
	background-color: ${theme.white};
	border-bottom: 1px solid ${theme.borderBGColor};
	.statusLable {
		.label {
			font-size: 18px;
			font-weight: 700;
		}
	}
	.errorLabel {
		margin-left: -30px;
		padding: 0;
	}
`;
export const ButtonWrapper = styled.div`
	position: fixed;
	background-color: ${theme.white};
	display: flex;
	padding: 20px 0;
	justify-content: center;
	width: calc(100% - 38px - 38px);
	bottom: 0;
	z-index: 1;
`;

export const CustomButton = styled(ButtonComponent)``;
export const Spacer = styled.div`
	margin-bottom: 6.8em;
`;

export const FieldWrapper = styled.div`
	display: flex;
	flex-direction: row;
	padding: 50px 0 20px 0;

	.title {
		font-size: 14px;
		min-width: 170px;
		padding-right: 20px;
	}

	.text-field-container {
		width: 100%;
		padding: 0 30px 0px 0px;
		& .ant-input {
			height: 50px;
		}
	}
`;
