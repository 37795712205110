import { IComment } from '../CommentComponent/types';

export const STATUS_OPTIONS = [
	{
		key: 1,
		label: '執筆中',
		value: 'writing',
	},
	{
		key: 2,
		label: '執筆済み',
		value: 'written',
	},
	{
		key: 3,
		label: '撮影済み',
		value: 'filmed',
	},
	{
		key: 4,
		label: 'デザイン中',
		value: 'designing',
	},
	{
		key: 5,
		label: 'デザイン済み',
		value: 'designed',
	},
	{
		key: 6,
		label: '修正中',
		value: 'revising',
	},
	{
		key: 7,
		label: '修正済み',
		value: 'modified',
	},
	{
		key: 8,
		label: '完了',
		value: 'completed',
	},
];

export const disabledValues = (compareValue: string) => {
	const values: string[] = [];

	const statusKey = STATUS_OPTIONS?.filter(
		(options) => options?.value === compareValue
	);

	STATUS_OPTIONS?.map((option) => {
		if (option?.key <= statusKey[0]?.key) {
			values?.push(option?.value);
		}
	});

	return values;
};

export interface IProjectProps {
	formik: any;
	onClick: () => void;
	isLoading: boolean;
	projectData: any;
}

export interface KeyVisual {
	images: string[];
	project_title: string;
	comments: IComment[];
}

export interface Story {
	point1: string;
	point2: string;
	point3: string;
	rich_text_data: string;
	comments: IComment[];
}

export interface ReturnData {
	title: string;
	amount: string;
	description: string;
	schedule_delivery: string;
	units: string;
	remarks: string;
}

export interface RiskAndChallenges {
	returnsData: ReturnData[];
	comments: IComment[];
}

export interface ImageSlideShow {
	images: string[];
	comments: IComment[];
}

export interface Project {
	status: string;
	key_visual: KeyVisual;
	story: Story;
	risk_and_challenges: RiskAndChallenges;
	image_slide_show: ImageSlideShow;
}
