/* eslint-disable no-console */
import { DatePicker, Spin, notification } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { styled } from 'styled-components';
import * as yup from 'yup';
import { addNews, getOneNews, updateNews } from '../../../services/news';
import { theme } from '../../../theme';
import { ButtonComponent, FieldWrapper, TextField } from '../../atoms';
import { Container, DatePickerWrapper, ErrorText, Lable } from './styles';

const CustomizedSpin = styled(Spin)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NewsModal = ({
	id,
	refetch,
	closeModal,
}: {
	id: string | null;
	refetch: () => void;
	closeModal: () => void;
}) => {
	const { t } = useTranslation();

	//Get all the news
	const {
		data: singleNews,
		isLoading: isDataLoading,
		isFetching: isDataFetching,
	} = useQuery(['getOneNews', id], getOneNews, {
		keepPreviousData: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});

	const { mutate, isLoading } = useMutation(addNews, {
		onSuccess: () => {
			notification.success({
				message: t('News created successfully'),
			});
			closeModal();
			refetch();
		},
		onError: () => {
			notification.error({
				message: t('An error has occurred. Please try again later'),
			});
			closeModal();
		},
	});

	const { mutate: updateNewsMutate, isLoading: updateNewsLoading } =
		useMutation(updateNews, {
			onSuccess: () => {
				notification.success({
					message: t('News updated successfully'),
				});
				refetch();
				closeModal();
			},
			onError: () => {
				notification.error({
					message: t('An error has occurred. Please try again later'),
				});
				closeModal();
			},
		});

	const validationSchema = yup.object().shape({
		date: yup.string().required('入力ください'),
		content: yup.string().required('入力ください'),
	});

	const initialData = {
		date: '',
		content: '',
	};

	const formik = useFormik({
		initialValues: id ? singleNews || initialData : initialData,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (value: any) => {
			id ? updateNewsMutate({ ...value, id }) : mutate(value);
		},
	});

	if (id && (isDataLoading || isDataFetching)) {
		return <CustomizedSpin />;
	}

	return (
		<Container>
			<form onSubmit={formik.handleSubmit}>
				<FieldWrapper>
					<DatePickerWrapper>
						<Lable>{'日付'}</Lable>
						<DatePicker
							name={'date'}
							placeholder={'日付を選択'}
							format={'YYYY年MM月DD日'}
							onChange={(value) => formik.setFieldValue('date', value)}
							value={formik.values.date ? dayjs(formik.values.date) : undefined}
							onBlur={formik.handleBlur}
							status={formik.touched.date && formik.errors.date ? 'error' : ''}
						/>
						{formik.touched.date && formik.errors.date ? (
							<ErrorText>{`${formik.errors.date}`}</ErrorText>
						) : null}
					</DatePickerWrapper>
				</FieldWrapper>
				<FieldWrapper>
					<Lable>{'内容'}</Lable>
					<TextField
						fullWidth={true}
						height={'52px'}
						className={'text-field'}
						showCounting
						maxLength={100}
						name={'content'}
						error={formik.touched.content && formik.errors.content}
						onChange={formik.handleChange}
						value={formik.values.content}
						onBlur={formik.handleBlur}
					/>
				</FieldWrapper>
				<div className={'btn-wrapper'}>
					<ButtonComponent
						type={'small'}
						width={'89px'}
						background={theme?.buttonColor}
						color={theme.white}
						loading={isLoading || updateNewsLoading}
						htmlType={'submit'}
					>
						{'保存'}
					</ButtonComponent>
				</div>
			</form>
		</Container>
	);
};
