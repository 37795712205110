/* eslint-disable no-console */
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useState } from 'react';
import { theme } from '../../../theme';
import { TextField } from '../../atoms';
import { CommentMessage } from '../../atoms/CommentMessage';
import {
	CommentBox,
	InputContainer,
	InputWrapper,
	Label,
	PreviousBtn,
	Title,
} from './styles';
import {
	ICommentProps,
	commentTypes,
	currentUserType,
	onChangedComment,
	sortCommentByDate,
} from './types';
const CommentComponent: React.FC<ICommentProps> = ({
	title,
	commentData,
	formik,
	commentType,
}) => {
	const comments = sortCommentByDate(commentData || []);
	const [displayCount, setDisplayCount] = useState(2);

	const handleViewMore = () => {
		if (comments.length > 2) {
			setDisplayCount((prevCount) => prevCount + 2);
		}
	};

	const handleViewLess = () => {
		setDisplayCount(2);
	};

	const renderedComments = comments?.slice(0, displayCount);
	const getCommentField = (commentType: string) => {
		switch (commentType) {
			case commentTypes[0]:
				return (
					<InputWrapper>
						<Label>{t('Add comment:')}</Label>
						<TextField
							name={'key_visual.comments'}
							width={'100%'}
							type={'textarea'}
							className={'text-field'}
							maxLength={200}
							showCount={true}
							placeholder={`${t('Enter comments')}`}
							height={'77px'}
							onBlur={formik.handleBlur}
							onChange={(e) => {
								const existingComments =
									formik.initialValues.key_visual.comments;
								onChangedComment(
									e,
									formik,
									`key_visual.comments`,
									existingComments
								);
							}}
						/>
					</InputWrapper>
				);
			case commentTypes[1]:
				return (
					<InputWrapper>
						<Label>{t('Add comment:')}</Label>
						<TextField
							name={'story.comments'}
							width={'100%'}
							type={'textarea'}
							className={'text-field'}
							maxLength={200}
							showCount={true}
							placeholder={`${t('Enter comments')}`}
							height={'77px'}
							onBlur={formik.handleBlur}
							onChange={(e) => {
								const existingComments = formik.initialValues.story.comments;
								onChangedComment(e, formik, `story.comments`, existingComments);
							}}
						/>
					</InputWrapper>
				);
			case commentTypes[2]:
				return (
					<InputWrapper>
						<Label>{t('Add comment:')}</Label>
						<TextField
							name={'risk_and_challenges.comments'}
							width={'100%'}
							type={'textarea'}
							className={'text-field'}
							maxLength={200}
							showCount={true}
							placeholder={`${t('Enter comments')}`}
							height={'77px'}
							onBlur={formik.handleBlur}
							onChange={(e) => {
								const existingComments =
									formik.initialValues.risk_and_challenges.comments;
								onChangedComment(
									e,
									formik,
									`risk_and_challenges.comments`,
									existingComments
								);
							}}
						/>
					</InputWrapper>
				);
			case commentTypes[3]:
				return (
					<InputWrapper>
						<Label>{t('Add comment:')}</Label>
						<TextField
							name={'image_slide_show.comments'}
							width={'100%'}
							type={'textarea'}
							className={'text-field'}
							maxLength={200}
							showCount={true}
							placeholder={`${t('Enter comments')}`}
							height={'77px'}
							onBlur={formik.handleBlur}
							onChange={(e) => {
								const existingComments =
									formik.initialValues.image_slide_show.comments;
								onChangedComment(
									e,
									formik,
									`image_slide_show.comments`,
									existingComments
								);
							}}
						/>
					</InputWrapper>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Title>{title}</Title>

			<CommentBox>
				{renderedComments?.map((comment, index) => (
					<CommentMessage
						key={index}
						label={comment.user}
						message={comment.comment}
						dateTime={`${dayjs(comment.created_at).format(
							'YYYY年MM月DD日 HH:mm'
						)}`}
						bgcolor={
							comment.user !== currentUserType
								? `${theme.commentBGColor}`
								: `${theme.commentBGColor1}`
						}
						bordercolor={
							comment.user !== currentUserType
								? `${theme.commentBorderColor}`
								: `${theme.commentBoderColor1}`
						}
					/>
				))}
				{comments.length == 0 || comments?.length <= 2 ? (
					<></>
				) : displayCount >= comments?.length ? (
					<PreviousBtn>
						<button onClick={() => handleViewLess()} type={'button'}>
							{t('Hide previous comments')}
						</button>
						<CaretUpOutlined />
					</PreviousBtn>
				) : (
					<PreviousBtn>
						<button onClick={() => handleViewMore()} type={'button'}>
							{t('See previous comments')}
						</button>
						<CaretDownOutlined />
					</PreviousBtn>
				)}

				<InputContainer>{getCommentField(commentType)}</InputContainer>
			</CommentBox>
		</>
	);
};
export { CommentComponent };
