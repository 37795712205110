import { styled } from 'styled-components';
import { theme } from '../../../theme';

export const Wrapper = styled.div`
	width: 100%;
	height: 62px;
	background: #ffffff;
	border: 1px solid #dedbd5;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 48px;

	.page-header-title {
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		opacity: 0.7;
	}
`;

export const ButtonContentContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	svg {
		width: 15px;
		height: 15px;
		background: rgba(251, 68, 108, 0.3);
		border-radius: 50%;
		fill: ${theme?.buttonColor};
	}
`;
