import { StyledButton, Wrapper } from './styles';
import { ButtonProps } from './types';

export const ButtonComponent: React.FC<ButtonProps> = ({
	children,
	htmlType,
	loading,
	onClick,
	...rest
}) => {
	return (
		<Wrapper {...rest}>
			<StyledButton
				onClick={onClick as any}
				htmlType={htmlType}
				loading={loading}
				{...rest}
			>
				{children}
			</StyledButton>
		</Wrapper>
	);
};
