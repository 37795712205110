import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

const RestrictedRoute = ({ children }: any) => {
	const { authenticated } = useContext(AuthContext);
	return authenticated ? <Navigate to={'/orders'} replace /> : children;
};

export default RestrictedRoute;
