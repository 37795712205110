import styled from 'styled-components';
import { theme } from '../../../theme';

export const MainContainer = styled.div`
	background-color: ${theme.coldWhite};
`;

export const TabContainer = styled.div`
	display: flex;
	border-top: 1px solid ${theme.borderBgColor};
	border-bottom: 1px solid ${theme.borderBgColor};
	justify-content: center;
	align-items: center;
	background-color: ${theme.white};
	height: 64.5px;
	width: 100%;
	margin-top: 20px;
`;

export const NavLinks = styled.ul`
	${theme.typography.typographyBody}
	display: flex;
	list-style: none;
	column-gap: 60px;

	@media (max-width: 1024px) {
		column-gap: 40px;
	}

	li > button {
		background-color: transparent;
		border: none;
		cursor: pointer;
		color: ${theme.black};
		font-weight: 500;
		opacity: 0.6;
		height: 64.5px;
		padding: 0 20px;
		font-size: 16px;

		@media (max-width: 1024px) {
			font-size: 14px;
		}
	}
	li > .active {
		color: ${theme.black};
		opacity: 1;
		border-bottom: 3px solid ${theme.buttonColor};
	}
`;

export const ErrorTitle = styled.div`
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	font-weight: 400;
	font-size: 16px;
	color: ${theme.buttonColor};
	line-height: 19.36px;
	margin: 20px 0;
`;

export const Wrapper = styled.div`
	padding: 0 38px;
	display: flex;
	justify-content: center;
	flex-direction: column;
`;
