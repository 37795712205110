import { API } from '../api';
import { IStatusData } from '../pages/status-management/type';

export const getStatus = async ({ queryKey }: any): Promise<IStatusData> => {
	return API.get(`/status/${queryKey[1]}`);
};

export const updateStatus = async (statusData: any): Promise<any> => {
	const { id } = statusData;
	const updatedDate = new Date().toString();

	delete statusData?.id;
	return API.put(`/status/${id}`, { ...statusData, updatedAt: updatedDate });
};
