import { Empty } from 'antd';
import React from 'react';
import { StyledTable, Wrapper } from './styles';
import { ITable } from './types';
import { useTranslation } from 'react-i18next';

const StyledTableComponent: React.FC<ITable> = ({
	className,
	dataSource,
	loading,
	columns,
	isSearch,
	scrollX,
	pagination,
	...rest
}) => {
	const { t } = useTranslation();
	return (
		<Wrapper className={className}>
			<StyledTable
				dataSource={dataSource}
				loading={loading}
				scroll={{ x: scrollX }}
				columns={columns}
				pagination={{
					locale: {
						next_page: t('Next Page') || '次のページ',
						prev_page: t('Previous Page') || '前のページ',
					},
					...pagination,
				}}
				locale={
					isSearch
						? {
								emptyText: (
									<Empty
										description={t(
											'There are no data that matches the search conditions.'
										)}
										image={Empty.PRESENTED_IMAGE_SIMPLE}
									/>
								),
						  }
						: {
								emptyText: (
									<Empty
										description={t('No data')}
										image={Empty.PRESENTED_IMAGE_SIMPLE}
									/>
								),
						  }
				}
				{...rest}
			/>
		</Wrapper>
	);
};

export { StyledTableComponent };
