import Menu from 'antd/lib/menu';
import { MenuTheme } from 'antd/lib/menu/MenuContext';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';

interface MenuGeneratorProps {
	theme?: MenuTheme;
	mode?: any;
	className?: string;
	defaultSelectedKeys?: string[];
	defaultOpenKeys?: string[];
	items?: any;
}

interface MenuItem {
	key: string;
	onClick?: () => void;
	icon?: JSX.Element;
	name: string;
	className: string;
	children?: MenuItem[];
	disabled?: boolean;
}

const renderMenuItem = (items: MenuItem[]): JSX.Element[] => {
	const menuItems: JSX.Element[] = [];
	for (const item of items) {
		let children: JSX.Element[] = [];
		if (item.children) {
			children = renderMenuItem(item.children);
		}
		if (children.length > 0) {
			menuItems.push(
				<SubMenu
					key={item.key}
					title={
						<span>
							{item.icon}
							<span>{item.name}</span>
						</span>
					}
					disabled={item.disabled}
					className={item.className}
				>
					{children}
				</SubMenu>
			);
		} else {
			menuItems.push(
				<Menu.Item
					key={item.key}
					onClick={item.onClick}
					disabled={item.disabled}
					className={item.className}
				>
					{item.icon}
					<span>{item.name}</span>
					{children}
				</Menu.Item>
			);
		}
	}
	return menuItems;
};

const MenuGenerator: React.FC<MenuGeneratorProps> = ({
	theme,
	mode,
	className,
	defaultSelectedKeys,
	defaultOpenKeys,
	items,
}) => {
	return (
		<Menu
			theme={theme}
			mode={mode}
			className={className}
			defaultSelectedKeys={defaultSelectedKeys}
			defaultOpenKeys={defaultOpenKeys}
		>
			{items && renderMenuItem(items)}
		</Menu>
	);
};

export { MenuGenerator };
