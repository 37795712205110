import { FieldArrayRenderProps } from 'formik';

export interface IUrl {
	url: string;
}
export interface IDesignData {
	formik: any;
	onClick: () => void;
	isLoading: boolean;
	savedData: any;
}

export interface IShooting {
	design: Array<IUrl>;
}

export const initialValues = {
	design: [
		{
			url: '',
		},
	],
};

export const handleCreateNewForm = (
	arrayHelpers: FieldArrayRenderProps,
	index: number
) => {
	arrayHelpers.insert(index, {
		url: '',
	});
};

export const isLastIndex = (index: number, formik: any) => {
	if (index == formik.values.designDataUrls?.length - 1) {
		return true;
	}
	return false;
};

export const handleFieldLimit = (formik: any) => {
	if (formik.values.designDataUrls?.length == 5) {
		return true;
	}
	return false;
};
