import { styled } from 'styled-components';
import { theme } from '../../../theme';

export const Wrapper = styled.div`
	background-color: #373e49;
	font-family: 'Noto Sans JP', sans-serif;
	position: sticky;
	top: 0;

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: rgba(0, 0, 0, 0.2);
		color: #fff;
	}

	.ant-menu-light
		.ant-menu-item:hover:not(.ant-menu-item-selected):not(
			.ant-menu-submenu-selected
		) {
		color: #fff;
	}

	.ant-menu-item:hover {
		background: #373a99;
		color: #fff;
	}

	.ant-menu {
		background: #373a99;
		font-size: 16px;
		height: calc(100vh - 64px);
	}

	.ant-menu-item {
		color: #d3d3d3;
		height: 55px;
		border-radius: 0px;
	}

	.ant-menu-inline .ant-menu-item {
		width: 100%;
		margin: 0px;
	}

	.ant-menu-inline .ant-menu-item:not(:last-child) {
		width: 100%;
		margin: 0px;
		border-bottom-width: 1px;
		border-bottom-color: #5e61c7;
		border-bottom-style: solid;
	}

	.ant-menu-inline .ant-menu-item::after {
		position: absolute;
		right: auto !important;
		top: 0;
		left: 0;
		border-right: 3px solid ${theme?.buttonColor};
	}

	.ant-menu-title-content {
		font-size: 16px;
		font-weight: 500;
		display: flex;
		align-items: center;
	}
`;

export const SideNavHeadWrapper = styled.div`
	height: 64px;
	font-size: 14px;
	padding: 10px;
	background: #1c1e60;
	text-align: center;
	box-shadow: inset 0px -1px 0px #373e49;
`;
export const SideNavIcon = styled.span`
	margin-right: 20px;
	display: flex;
	svg,
	img {
		width: 22px;
		height: 22px;
	}
`;
