import { Popconfirm, notification } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteNews, getAllNews } from '../../../services/news';
import { checkPageParam } from '../../../utils';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { MainPageWrapper } from '../../../utils/global';
import { Popup } from '../../atoms';
import { PageHeader, Table } from '../../molecules';
import { NewsModal } from '../NewsModal';
import { TableContainer } from './styles';

export const NewsListTable = () => {
	// current editing item id and modal open state
	const [selectedId, setSelectedId] = useState<string | null>(null);

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const page = searchParams.get('page');

	//Get all the news
	const {
		isLoading,
		isFetching,
		data: tableData,
		refetch,
	} = useQuery(['getAllNews', page, DEFAULT_PAGE_SIZE], getAllNews, {
		enabled: false,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const { mutate: deleteMutate } = useMutation(deleteNews, {
		onSuccess: () => {
			notification.success({
				message: t('News deleted successfully'),
			});
			refetch();
		},
		onError: () => {
			notification.error({
				message: t('An error has occurred. Please try again later'),
			});
		},
	});

	const columns = [
		{
			title: t('Date'),
			dataIndex: 'date',
			render: (date: any) => {
				return date ? dayjs(date).format('YYYY年MM月DD日') : '-';
			},
			width: 190,
		},
		{
			title: t('Content'),
			dataIndex: 'content',
		},
		{
			title: t('Update'),
			dataIndex: 'id',
			width: 50,
			render: (id: string) => {
				return (
					<div className={'actions'}>
						<a
							onClick={() => {
								setSelectedId(id);
							}}
						>
							<img
								src={'/assets/icons/edit-icon.svg'}
								className={'action-svg'}
							/>
						</a>
						<a>
							<Popconfirm
								placement={'topRight'}
								title={t('Are you sure you want to delete?')}
								okText={t('Yes')}
								cancelText={t('No')}
								onConfirm={() => deleteMutate(id)}
							>
								<img
									src={'/assets/icons/delete.svg'}
									className={'action-svg delete'}
								/>
							</Popconfirm>
						</a>
					</div>
				);
			},
		},
	];

	useEffect(() => {
		checkPageParam('news', navigate);
		refetch();
	}, []);

	const handlePageChange = (page: number) => {
		navigate(`/news?page=${page}`);
	};

	//Open close News popup modal
	const closeModal = () => {
		setSelectedId(null);
	};

	const openNewsModal = (id: string | null) => {
		setSelectedId(id);
	};
	return (
		<>
			<PageHeader
				title={'News'}
				showButton
				handleClick={() => {
					openNewsModal('');
				}}
			/>
			<MainPageWrapper>
				<TableContainer>
					<Table
						columns={columns}
						dataSource={tableData?.data || []}
						loading={isLoading || isFetching}
						isSearch={true}
						pagination={{
							current: Number(page) || 1,
							total: tableData?.count,
							pageSize: DEFAULT_PAGE_SIZE,
							showSizeChanger: false,
							onChange: handlePageChange,
							showTotal: (total, range) => {
								return (
									<>
										{total}
										{'件, '}
										{`${range[0]} ~ ${range[1]}`} {'件名を表示'}
									</>
								);
							},
						}}
					/>
				</TableContainer>
				<Popup
					title={
						selectedId === null || selectedId === ''
							? 'ニュース追加'
							: 'ニュースを編集'
					}
					popupOpen={selectedId !== null || selectedId === ''}
					onClose={closeModal}
					width={734}
				>
					<NewsModal
						id={selectedId}
						refetch={refetch}
						closeModal={closeModal}
					/>
				</Popup>
			</MainPageWrapper>
		</>
	);
};
