import { styled } from 'styled-components';
import { theme } from '../../../theme';
import { Button } from 'antd';

export const Wrapper = styled.div`
	display: contents;
	& .ant-btn-primary {
		box-shadow: 0px 0px 12px rgba(28, 86, 225, 0.51);
		font-size: 16px;
		font-weight: 700;
	}

	& .ant-btn-link {
		box-shadow: none;
		background: transparent;
		color: ${theme.accent_600};
	}
`;

export const StyledButton: any = styled(Button)`
	&:hover {
		box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.4);
		-webkit-box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.4);
	}

	&:focus {
		border: 1px solid ${theme.primary_600};
		box-shadow: none !important;
	}

	&:disabled {
		background-color: ${({ backgroundColorWhenDisabled }: any) =>
			backgroundColorWhenDisabled
				? backgroundColorWhenDisabled
				: theme.buttonColorTabContents};
		cursor: not-allowed;
		/* border: 1px solid rgba(251, 68, 108, 0.3); */
		box-shadow: none !important;
		color: rgba(0, 0, 0);
	}

	display: flex;
	justify-content: center;
	align-items: center;

	font-family: ${theme.typography.typographyBody.fontFamily};

	border-color: ${({ bordercolor }: any) =>
		bordercolor ? bordercolor : theme.buttonColor};
	border-radius: ${({ borderradius }: any) => {
		return borderradius ?? '62px';
	}};

	margin: ${({ margin }: any) => {
		return margin && margin;
	}};

	height: ${({ type, height }: any) => {
		switch (type) {
			case 'large':
				return '54px';
			case 'medium':
				return '41px';
			case 'small':
				return '29px';
			default:
				return height;
		}
	}};

	background: ${({ background }: any) => {
		return background ?? theme.buttonColor;
	}};

	color: ${({ color }: any) => {
		return color ? `${color} !important` : 'white';
	}};
	font-size: ${({ type }: any) => {
		switch (type) {
			case 'large':
				return '18px';
			case 'medium':
				return '16px';
			case 'small':
				return '16px';
		}
	}};
	font-weight: 500;
	width: ${({ width }: any) => {
		return width ? `${width}` : '100%';
	}};

	min-width: ${({ minwidth }: any) => {
		return minwidth && `${minwidth}px`;
	}};

	min-height: ${({ minheight }: any) => {
		return minheight && `${minheight}px`;
	}};

	span {
		opacity: ${({ opacity }: any) => {
			return opacity ?? 1;
		}};

		font-size: ${({ fontSize }: any) => {
			return fontSize;
		}};
		font-weight: ${({ fontWeight }: any) => {
			return fontWeight;
		}};
		line-height: ${({ lineheight }: any) => {
			return lineheight;
		}};
	}
`;
