import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuGenerator } from '../../atoms/MenuGenerator';
import { SideNavHeadWrapper, SideNavIcon, Wrapper } from './styles';
import { BellOutlined, UserOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { AuthContext } from '../../../AuthProvider';

const SideNav = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const selectKey = location.pathname.split('/');
	if (selectKey[1] === 'menu1-submenu1') selectKey[1] = 'menu1';
	const selectKeyDefault = selectKey[1] || 'menu1';

	const { logout } = useContext(AuthContext);

	const onMenuChange = () => {
		const orders = localStorage.getItem('order-formik-values');
		const users = localStorage.getItem('users-formik-values');

		orders && localStorage.removeItem('order-formik-values');
		users && localStorage.removeItem('users-formik-values');
	};

	const handleSignOut = async () => {
		onMenuChange();
		logout();
		navigate('/');
	};

	const menus = [
		{
			className: null,
			key: 'orders',
			onClick: () => {
				onMenuChange();
				navigate('/orders?page=1');
			},
			icon: (
				<SideNavIcon>
					<img src={'/assets/icons/users-outlined.svg'} alt={''} />
				</SideNavIcon>
			),
			name: t('Order list'),
		},
		{
			className: null,
			key: 'news',
			onClick: () => {
				onMenuChange();
				navigate('/news?page=1');
			},
			icon: (
				<SideNavIcon>
					<BellOutlined />
				</SideNavIcon>
			),
			name: t('News'),
		},
		{
			className: null,
			key: 'users',
			onClick: () => {
				onMenuChange();
				navigate('/users?page=1');
			},
			icon: (
				<SideNavIcon>
					<UserOutlined />
				</SideNavIcon>
			),
			name: t('Users'),
		},
		{
			className: null,
			key: 'logout',
			onClick: handleSignOut,
			icon: (
				<SideNavIcon>
					<img src={'/assets/icons/logout.svg'} />
				</SideNavIcon>
			),
			name: t('Logout'),
		},
	];

	return (
		<Wrapper>
			<SideNavHeadWrapper>
				<img src={'/assets/icons/logo.png'} width={50} height={50} />
			</SideNavHeadWrapper>
			<MenuGenerator
				mode={'inline'}
				defaultSelectedKeys={[selectKeyDefault]}
				items={menus}
			/>
		</Wrapper>
	);
};

export { SideNav };
