import styled from 'styled-components';

// themes
import { theme } from '../../../theme';

export const Wrapper = styled.div<any>`
	display: flex;
	flex-direction: ${({ inlineError }: any) => (inlineError ? 'row' : 'column')};
	align-items: ${({ inlineError }: any) => (inlineError ? 'center' : 'none')};
`;

// Label styling
export const Label = styled.label`
	${theme.typography.typographySmall}
	display: block;
	margin-right: 36px;
	font-weight: 500;
	margin-bottom: 5px;
	line-height: 19px;
	color: ${theme.label};
	min-width: 50px;
	span {
		color: red;
	}
`;

// select container styling
export const SelectContainer = styled.div<{
	$error?: boolean;
	$width?: string | number;
	$maxWidth?: string | number;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;

	.caret-down-icon {
		svg {
			fill: ${theme?.black};
			width: 15px;
			height: 17px;
		}
	}

	.ant-select {
		width: ${({ $width }) => $width || '100%'};
		min-width: ${({ $maxWidth }) => $maxWidth || '274px'};
	}

	.ant-select-selection-placeholder {
		color: ${theme.greyPlaceHolder} !important;
	}

	.ant-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-radius: 4px;
		background-color: ${theme.neutral_100};
		height: 42px;
		display: flex;
		align-items: center;
	}

	.ant-select.ant-select:not(.ant-select-customize-input):hover
		.ant-select-selector {
		border-color: ${({ $error }) =>
			$error ? theme.accent_800 : theme.neutral_400};
	}

	.ant-select.ant-select:not(.ant-select-customize-input).ant-select-open
		.ant-select-selector {
		border-color: ${({ $error }) =>
			$error ? theme.accent_800 : theme.primary_600};
	}

	.ant-select.ant-select:not(.ant-select-customize-input).ant-select-open:focus
		.ant-select-selector {
		border-color: ${({ $error }) =>
			$error ? theme.accent_800 : theme.primary_600};
	}

	.ant-select-dropdown .ant-select-item {
		padding-top: 10.5px !important;
		padding-bottom: 10.5px !important;
	}

	.ant-select-focused {
		box-shadow: none;
		border-color: ${({ $error }) =>
			$error ? theme.accent_800 : theme.primary_600};
	}

	/* selected value styling */
	.ant-select.ant-select-single
		.ant-select-selector
		.ant-select-selection-item {
		font-size: 14px;
		font-weight: 300;
		color: ${theme.neutral_600};
	}

	.ant-select.ant-select-disabled
		.ant-select-single
		.ant-select-selector
		.ant-select-selection-item {
		color: ${theme.neutral_900};
	}

	/* Placeholder styling */
	.ant-select.ant-select-single
		.ant-select-selector
		.ant-select-selection-placeholder {
		font-size: 14px;
		font-weight: 400;
		color: ${({ $error }) => ($error ? theme.accent_800 : theme.black)};
	}

	/* font styling of dropdown box list */
	.ant-select-dropdown .ant-select-item-option-content {
		font-size: 14px !important;
		font-weight: 300 !important;
	}

	/* styling while focused */
	.ant-select-focused.ant-select:not(.ant-select-disabled):not(
			.ant-select-customize-input
		):not(.ant-pagination-size-changer)
		.ant-select-selector {
		border-color: ${({ $error }) =>
			$error ? theme.accent_800 : theme.primary_600} !important;
	}

	/* styling when Disabled  */
	.ant-select-disabled.ant-select:not(.ant-select-customize-input)
		.ant-select-selector {
		background-color: #f0f0f0;
		border-color: #dedede;
	}

	.ant-select-disabled.ant-select:not(.ant-select-customize-input):hover
		.ant-select-selector {
		border-color: #dedede;
	}
`;

export const ErrorStyled = styled.span`
	font-size: 12px;
	color: ${theme.accent_800};
	margin-left: 85px;
	padding-top: 4px;
`;
