import { NavigateFunction } from 'react-router-dom';

export const checkPageParam = (route: string, navigate: NavigateFunction) => {
	const searchParams = new URLSearchParams(window.location.search);
	if (!searchParams.has('page')) {
		navigate(`/${route}?page=1`);
	}
};

export const checkDisabledField = (array: any[], savedData: any[]) => {
	const checkValuesOldArray = array
		?.map((item: { url: any }) => !!item.url && item.url)
		?.filter(Boolean);

	const checkValuesNewArray = savedData
		?.map((item: { url: any }) => !!item.url && item.url)
		?.filter(Boolean);

	console.log(
		checkValuesNewArray?.length && !checkValuesOldArray?.length,

		array?.length !== checkValuesOldArray?.length,

		JSON.stringify(checkValuesOldArray) === JSON.stringify(checkValuesNewArray),

		JSON.stringify(array) === JSON.stringify(savedData),

		!checkValuesOldArray?.length
	);

	return checkValuesNewArray?.length && !checkValuesOldArray?.length
		? false
		: array?.length !== checkValuesOldArray?.length
		? true
		: JSON.stringify(checkValuesOldArray) ===
		  JSON.stringify(checkValuesNewArray)
		? true
		: JSON.stringify(array) === JSON.stringify(savedData)
		? true
		: !checkValuesOldArray?.length;
};

export const getNumberWithCommas = (value: string) =>
	value
		.replace(/^0+/, '')
		.replace(/\D/g, '')
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getTimestampInMillisecond = (dateObject: {
	_seconds: number;
	_nanoseconds: number;
}) => {
	const timestampMilliseconds =
		dateObject?._seconds * 1000 + dateObject?._nanoseconds / 1000000;

	return timestampMilliseconds;
};
