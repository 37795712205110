import { FieldArrayRenderProps } from 'formik';

export interface IReturnData {
	title: string;
	amount: string;
	description: string;
	schedule_delivery: string;
	units: string;
	remarks: string;
}
export interface RiskChallengesProps {
	formik: any;
}

export interface IReturn {
	returnsData: Array<IReturnData>;
}

export const initialValues = {
	returnsData: [
		{
			title: '',
			amount: '',
			description: '',
			schedule_delivery: '',
			units: '',
			remarks: '',
		},
		{
			title: '',
			amount: '',
			description: '',
			schedule_delivery: '',
			units: '',
			remarks: '',
		},
		{
			title: '',
			amount: '',
			description: '',
			schedule_delivery: '',
			units: '',
			remarks: '',
		},
	],
};

export const handleCreateNewForm = (
	arrayHelpers: FieldArrayRenderProps,
	index: number
) => {
	arrayHelpers.insert(index, {
		title: '',
		amount: '',
		description: '',
		schedule_delivery: '',
		units: '',
		remarks: '',
	});
};

export const isLastIndex = (index: number, formik: any) => {
	if (index == formik.values?.risk_and_challenges?.returnsData.length - 1) {
		return true;
	}
	return false;
};

export const handleFieldLimit = (formik: any) => {
	if (formik.values?.risk_and_challenges?.returnsData.length == 15) {
		return true;
	}
	return false;
};
