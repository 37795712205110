const ORDERS_STATUS_OPTIONS = [
	{
		label: '執筆中',
		value: 'Writing',
	},
	{
		label: '執筆済み',
		value: 'Written',
	},
	{
		label: '撮影済み',
		value: 'Filmed',
	},
	{
		label: 'デザイン中',
		value: 'Designing',
	},
	{
		label: 'デザイン済み',
		value: 'Designed',
	},
	{
		label: '修正中',
		value: 'Revising',
	},
	{
		label: '修正済み',
		value: 'Modified',
	},
	{
		label: '完了',
		value: 'Completed',
	},
];

export { ORDERS_STATUS_OPTIONS };
