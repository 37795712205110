/* eslint-disable no-console */
import { API } from '../api';

interface IOrderParams {
	email?: string;
	name?: string;
	corporate_name?: string;
	status?: string;
	page?: number;
	pageSize?: number;
}

export const getAllOrders = async (pageNumber: string | null): Promise<any> => {
	const params = {} as IOrderParams;
	params.page = pageNumber ? Number(pageNumber) : 1;
	params.pageSize = 10;
	return API.get(`/orders`, { params });
};

export const updateRemarks = async (data: any): Promise<any> => {
	return API.put(`/orders/update-remarks/${data.id}`, {
		remarks: data.remarks,
	});
};
