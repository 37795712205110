import { IStatusData } from '../../../pages/status-management/type';
import { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
export const tabMenus = [
	{
		key: 'schedule',
		name: 'Schedule',
	},
	{
		key: 'project-page',
		name: 'Project page',
	},
	{
		key: 'shooting-data',
		name: 'Shooting data',
	},
	{
		key: 'design-data',
		name: 'Design data',
	},
	{
		key: 'feedback',
		name: 'Feedback',
	},
];

export const handleChangeTabAfterSubmit = (
	activeKey: string,
	setActiveKey: Dispatch<SetStateAction<string>>
) => {
	if (activeKey === 'project-page') {
		setActiveKey('shooting-data');
	} else if (activeKey === 'shooting-data') {
		setActiveKey('design-data');
	} else if (activeKey === 'design-data') {
		setActiveKey('feedback');
	} else if (activeKey === 'schedule') {
		setActiveKey('project-page');
	}
};

export const projectValidationSchema = Yup.object().shape({
	risk_and_challenges: Yup.object().shape({
		returnsData: Yup.array().of(
			Yup.object().shape({
				title: Yup.string(),
				amount: Yup.string(),
				description: Yup.string(),
				schedule_delivery: Yup.string(),
				units: Yup.number()
					.typeError('数字のみ入力できます')
					.integer('数字のみ入力できます')
					.min(1, '1以上の数字を入力してください')
					.max(9999, '最大4桁まで入力可能です'),
				remarks: Yup.string(),
			})
		),
	}),
	image_slide_show: Yup.object().shape({
		images: Yup.array(),
	}),
	key_visual: Yup.object().shape({
		images: Yup.array(),
	}),
	status: Yup.string(),
});

export const urlshootingValidation = Yup.object().shape({
	shootingDataUrls: Yup.array().of(
		Yup.object().shape({
			url: Yup.string().matches(
				/^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i,
				'URLは正しくありません'
			),
		})
	),
});
export const urlFeedValidation = Yup.object().shape({
	feedbackDataUrls: Yup.array().of(
		Yup.object().shape({
			url: Yup.string().matches(
				/^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i,
				'URLは正しくありません'
			),
		})
	),
});

export const urlDesignValidation = Yup.object().shape({
	designDataUrls: Yup.array().of(
		Yup.object().shape({
			url: Yup.string().matches(
				/^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i,
				'URLは正しくありません'
			),
		})
	),
});

export const scheduleInitialValues = {
	first_application: null,
	last_application: null,
	project_publish: null,
	project_end: null,
};

export const projectInitialValues = {
	status: '',
	key_visual: {
		images: [],
		project_title: '',
		comments: [],
	},
	story: {
		point1: '',
		point2: '',
		point3: '',
		rich_text_data: '',
		comments: [],
	},
	risk_and_challenges: {
		returnsData: [
			{
				title: '',
				amount: '',
				description: '',
				schedule_delivery: '',
				units: '',
				remarks: '',
			},
			{
				title: '',
				amount: '',
				description: '',
				schedule_delivery: '',
				units: '',
				remarks: '',
			},
			{
				title: '',
				amount: '',
				description: '',
				schedule_delivery: '',
				units: '',
				remarks: '',
			},
		],
		comments: [],
	},
	image_slide_show: {
		images: [],
		comments: [],
	},
};

export const shootingUrls = [
	{
		url: '',
	},
];

export const designUrls = [
	{
		url: '',
	},
];

export const feedbackUrls = [
	{
		url: '',
	},
];

export interface IStatusProps {
	statusData?: IStatusData;
	refetchStatus: () => void;
}
