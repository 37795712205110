import { DatePicker } from 'antd';
import 'dayjs/locale/ja';
import i18next, { t } from 'i18next';
import { Wrapper } from './styles';
import { DatePickerProps } from './types';

export const CustomDatePicker: React.FC<DatePickerProps> = ({
	label,
	value,
	placeholder,
	name,
	onChange,
}) => {
	return (
		<Wrapper>
			{label && <div className={'label'}>{t(label)}</div>}
			<DatePicker
				name={name}
				placeholder={placeholder || ''}
				onChange={onChange}
				value={value}
				format={i18next.language === 'ja' ? 'YYYY年MM月DD日' : 'YYYY-MM-DD'}
				allowClear={true}
			/>
		</Wrapper>
	);
};
