export const DEFAULT_PAGE_SIZE = 10;
export const PAST_ORDER_LAST_ID = 482;

//Create deadline months option
export const SCHEDULE_OPTIONS = () => {
	const scheduleOptions = [] as any;

	for (let i = 0; i < 12; i++) {
		scheduleOptions?.push({
			label: `${i + 1}月`,
			value: `${i + 1}月`,
		});
	}

	return scheduleOptions;
};
