import styled from 'styled-components';
import { theme } from '../../../theme';

export const CommentBox = styled.div`
	border: 1px solid ${theme.commentOuterBorder};
	border-radius: 5px;
	padding: 25px;
	background-color: ${theme.white};
	display: flex;
	flex-direction: column;
	row-gap: 12px;
`;
export const InputContainer = styled.div`
	padding-bottom: 28px;
	.label {
		margin-right: 68px;
	}
`;
export const Title = styled.div`
	font-size: 18px;
	font-weight: 700;
	color: ${theme.black};
	margin-bottom: 28px;
`;

export const PreviousBtn = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
	margin: 10px 0;
	button {
		border: none;
		background-color: transparent;
		color: ${theme.primaryColor};
		font-size: 16px;
		font-weight: 400px;
		text-decoration: underline;
		text-underline-offset: 4px;
		cursor: pointer;
		padding: 0;
	}
	.anticon {
		font-size: 24px;
		color: ${theme.dropdowniconColor};
	}
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export const Label = styled.div`
	color: ${theme.label};
	font-weight: 500;
	margin-bottom: 5px;
	font-size: 14px;
	min-width: 120px;
	display: flex;
	align-items: center;
	word-break: keep-all;
`;
